import { User } from 'firebase/auth';
import { httpsCallable } from 'firebase/functions';
import { logError } from './Firestore';
import { functions, functionsWest3 } from './firebaseProviders';

export const getPortalUrl = async (user: User | null): Promise<string> => {
    let dataWithUrl: any;
    try {
        const functionRef = httpsCallable(
            functionsWest3,
            'ext-firestore-stripe-payments-createPortalLink'
        );
        const { data } = await functionRef({
            customerId: user?.uid,
            returnUrl: window.location.origin,
        });

        // Add a type to the data
        dataWithUrl = data as { url: string };
        console.log('Reroute to Stripe portal: ', dataWithUrl.url);
    } catch (error) {
        console.error(error);
    }

    return new Promise<string>((resolve, reject) => {
        if (dataWithUrl.url) {
            resolve(dataWithUrl.url);
        } else {
            reject(new Error('No url returned'));
        }
    });
};

export async function handleOnCallCancelSubscriptionAtPeriodEnd(uid: string) {
    try {
        // create a reference to the back end https function
        const onCallCancelSubscriptionAtPeriodEnd = httpsCallable(
            functions,
            'onCallCancelSubscriptionAtPeriodEnd'
        );
        const result = await onCallCancelSubscriptionAtPeriodEnd({
            userId: uid,
        });
        return result.data as string;
    } catch (e: any) {
        logError(String(e.message));
        throw new Error(e.message);
    }
}

export async function handleOnCallCreateStripeSession(
    uid: string,
    priceId: string,
    quantity: number,
    locationOrigin: string,
    trialPeriod?: number
) {
    try {
        // create a reference to the back end https function
        const onCallCreateStripeSession = httpsCallable(
            functions,
            'onCallCreateStripeSession'
        );
        const result = await onCallCreateStripeSession({
            uid,
            priceId,
            quantity,
            locationOrigin,
            trialPeriod,
        });
        return result.data as string;
    } catch (e: any) {
        logError(String(e.message));
        throw new Error(e.message);
    }
}
