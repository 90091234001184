import { BoltIcon, LockClosedIcon } from '@heroicons/react/20/solid';
import { LockClosedIcon as LockClosedIconOutline } from '@heroicons/react/24/outline';
import { motion } from 'framer-motion';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../context/UserContext';
import { PAID_PLANS, isPaidPlan } from '../../util/utils';
// import {}
interface PaidWarningOverlayProps {
    children: ReactNode;
    withButton?: boolean;
    paidPlan: PAID_PLANS;
    isSmall?: boolean;
}

export const PaidWarningOverlay = ({
    children,
    withButton,
    paidPlan,
    isSmall,
}: PaidWarningOverlayProps) => {
    const { subscription } = useAuth();

    return (
        <div className="w-full h-full relative">
            {children}
            {isPaidPlan(subscription, paidPlan) && (
                <div
                    className="flex flex-col items-center justify-center w-full h-full absolute top-0 left-0 rounded-lg z-20"
                    style={{
                        backgroundColor: 'rgba(255, 255, 255, 0.85)',
                    }}
                >
                    <div
                        className={`flex items-center justify-center rounded-full bg-[#202020] ${
                            isSmall ? 'w-[54px] h-[54px]' : 'w-[84px] h-[84px]'
                        }`}
                    >
                        {isSmall ? (
                            <LockClosedIconOutline className="w-[20px] h-[20px] text-[#FFF]" />
                        ) : (
                            <LockClosedIcon className="w-8 h-8 text-[#FFF]" />
                        )}
                    </div>
                    <p className="text-[16px] text-[#202020] font-medium mt-[24px] mb-[4px]">
                        This is a Paid feature
                    </p>
                    <p className="text-[14px] text-[#202020] text-center">
                        Upgrade your account to use this feature
                    </p>
                    {withButton && (
                        <motion.div
                            whileTap={{ scale: 0.9 }}
                            style={{
                                WebkitTapHighlightColor: 'transparent',
                            }}
                            className="flex items-center justify-center px-3 mt-6"
                        >
                            <Link
                                to="/subscription"
                                className="p-3.5 text-center w-full flex items-center justify-center gap-3 px-6 py-2 text-sm font-medium text-white transition-colors duration-300 bg-[#2C71F6] shadow-sm hover:bg-blue-500/80 rounded-[6px]"
                            >
                                <BoltIcon className="w-[18px] h-[18px]" />
                                Upgrade
                            </Link>
                        </motion.div>
                    )}
                </div>
            )}
        </div>
    );
};
