import { useEffect, useState } from 'react';
import { useAuth } from '../context/UserContext';
import { getUserRole } from '../util/Auth';

const useCustomClaim = () => {
    const { user } = useAuth();
    const [userRole, setUserRole] = useState<string | object>('');

    useEffect(() => {
        getUserRole().then((data) => {
            if (data) {
                setUserRole(data);
                localStorage.setItem('user-role', data as string);
            } else {
                //set subscription for old accounts without userRole
                setUserRole('basic');
            }
        });
    }, [user]);

    return { userRole };
};

export default useCustomClaim;
