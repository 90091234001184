import classNames from 'classnames';

import { motion } from 'framer-motion';
import React, { ReactNode, forwardRef } from 'react';
import { AriaButtonProps } from 'react-aria';

import {
    BUTTON_BASE_CLASSES,
    BUTTON_IMPACT_CLASSES,
    BUTTON_SHAPE_CLASSES,
    BUTTON_SIZE_CLASSES,
} from './constants';
import { Spinner } from '../Spinner/Spinner';
import { mapIcons } from '../InputField/InputField';
import { Tooltip } from '../Tooltip/Tooltip';

export interface ButtonProps extends AriaButtonProps {
    variant?: 'primary' | 'secondary' | 'text' | 'error' | 'custom' | 'green';
    size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
    focus?: 'outline' | 'shadow' | 'none';
    impact?: 'bold' | 'none' | 'light' | 'link';
    disabled?: boolean;
    loading?: boolean;
    prefixIcon?: React.ReactNode;
    shape?: 'rounded' | 'square' | 'pill';
    suffixIcon?: React.ReactNode;
    tooltipText?: string;
    tooltipPosition?: 'top' | 'bottom' | 'left' | 'right';
    bgColor?: string;
    textColor?: string;
    form?: string;
    hoverBgColor?: string;
    hoverTextColor?: string;
    children?: ReactNode;
    className?: string;
    onClick?: (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    actAsNormalButton?: boolean;
    style?: React.CSSProperties;
    badge?: number;
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
    (
        {
            tooltipPosition,
            tooltipText,
            className,
            variant = 'primary',
            impact = 'bold',
            loading,
            shape = 'rounded',
            size = 'md',
            prefixIcon,
            suffixIcon,
            children,
            badge,
            id = 'button',
            ...rest
        },
        ref
    ) => {
        return (
            <span
                id={id}
                role="button"
                aria-label="button"
                data-tooltip-content={tooltipText}
                data-tooltip-place={tooltipPosition}
                data-tooltip-id={id}
            >
                <motion.button
                    className={classNames(
                        BUTTON_BASE_CLASSES,
                        BUTTON_SIZE_CLASSES[size],
                        BUTTON_IMPACT_CLASSES[variant][impact],
                        BUTTON_SHAPE_CLASSES[shape],
                        className
                    )}
                    ref={ref}
                    {...rest}
                >
                    {prefixIcon && mapIcons(prefixIcon)}
                    {loading && (
                        <>
                            <Spinner className="absolute h-4 w-4" />
                            <span aria-readonly="true" className="hidden">
                                Loading...
                            </span>
                        </>
                    )}
                    <span
                        className={classNames(
                            'transition',
                            loading ? 'opacity-0' : 'opacity-100'
                        )}
                    >
                        {children}
                    </span>
                    {suffixIcon && mapIcons(suffixIcon)}
                    {!!badge && (
                        <span className="absolute right-0 top-0 flex h-[14px] w-[14px] content-center items-center justify-center rounded-full bg-primary-500 text-label/regular text-white">
                            {badge}
                        </span>
                    )}
                </motion.button>

                <Tooltip contentId={id} title={tooltipText} />
            </span>
        );
    }
);
Button.displayName = 'Button';
