import { User } from 'firebase/auth';
import { httpsCallable } from 'firebase/functions';
import { logError } from './Firestore';
import { functions } from './firebaseProviders';
import { UserRoleType } from '../hooks/useGetUserRole';
import { SwapkaartType } from '../Types/Swapkaart';

export async function manualSynchFromRealPad(projectID: string) {
    try {
        const synchRealPad = httpsCallable(functions, 'synchRealpad');

        // Calls a function with price and multiplies 70lv * 2
        const result = await synchRealPad({
            projectID: projectID,
        });

        // returns secretToken
        return result.data;
    } catch (error: any) {
        logError(String(error.message));
        console.log(error);
        return false;
    }
}
export async function resizeImage(file: any) {
    try {
        const resizeImage = httpsCallable(functions, 'resizeImage');
        const resultPrice = await resizeImage({
            input: file,
        });

        return resultPrice.data;
    } catch (error: any) {
        logError(String(error.message));
        console.log(error);
        return false;
    }
}
export async function cancelPaymentIntent(paymentIntentKey: string) {
    try {
        const cancelIntent = httpsCallable(functions, 'cancelPaymentIntent');

        const resultPrice = await cancelIntent({
            paymentIntentKey,
        });

        return resultPrice.data;
    } catch (error: any) {
        logError(String(error.message));
        console.log(error);
        return false;
    }
}

//create custom qr-code for user
export async function generateQRCodeFunction(payload: {
    QRname: string;
    url: string;
}) {
    try {
        // create a reference to the back end https function
        const generateQRCodeRef = httpsCallable(functions, 'generateQRCode');
        const result = await generateQRCodeRef({ ...payload });
        return result.data as string;
    } catch (e: any) {
        logError(String(e.message));
        throw new Error(e.message);
    }
}

export async function logErrorsFromWeb(
    triger: string,
    payload: any,
    error: any
) {
    try {
        const logErrorsFromWeb = httpsCallable(functions, 'logErrorsFromWeb');

        const log = await logErrorsFromWeb({
            triger: triger,
            payload: payload,
            error: String(error),
        });

        return true;
    } catch (error: any) {
        logError(String(error.message));
        console.log(error);
        return false;
    }
}

export async function handleUserCustomClaims({ uid }: { uid: User['uid'] }) {
    try {
        // create a reference to the back end https function
        const generateCustomClaim = httpsCallable(
            functions,
            'setCustomUserClaims'
        );
        const result = await generateCustomClaim({ uid });
        return result.data as string;
    } catch (e: any) {
        logError(String(e.message));
        throw new Error(e.message);
    }
}

export async function handleUserCustomClaimsPro({ uid }: { uid: User['uid'] }) {
    try {
        // create a reference to the back end https function
        const generateCustomClaim = httpsCallable(
            functions,
            'setCustomUserClaimsPro'
        );
        const result = await generateCustomClaim({ uid });
        return result.data as string;
    } catch (e: any) {
        logError(String(e.message));
        throw new Error(e.message);
    }
}

export async function handleUserCustomTeamsClaims({
    uid,
    teamOwnerId,
}: {
    uid: User['uid'];
    teamOwnerId: string;
}) {
    try {
        // create a reference to the back end https function
        const generateCustomClaim = httpsCallable(
            functions,
            'setCustomUserClaimsTeams'
        );
        const result = await generateCustomClaim({ uid, teamOwnerId });
        return result.data as string;
    } catch (e: any) {
        logError(String(e.message));
        throw new Error(e.message);
    }
}

export async function handleSetUserRoleClaims(
    uid: string,
    ownerId: string,
    role: string
) {
    try {
        // create a reference to the back end https function
        const generateCustomClaim = httpsCallable(
            functions,
            'setUserRoleClaims'
        );
        const result = await generateCustomClaim({ uid, ownerId, role });
        return result.data as string;
    } catch (e: any) {
        logError(String(e.message));
        throw new Error(e.message);
    }
}

export async function handleIfUserExists(email: string) {
    try {
        // create a reference to the back end https function
        const checkIfUserExists = httpsCallable(functions, 'checkIfUserExists');

        return checkIfUserExists({ email });
    } catch (e: any) {
        logError(String(e.message));
        throw new Error(e.message);
    }
}

export async function handleGetUserRoleWithOwnerId(
    ownerId: string
): Promise<UserRoleType> {
    try {
        // create a reference to the back end https function
        const res = await fetch(
            'http://127.0.0.1:5001/codebooth-a4b70/europe-west1/expressAppfXLqkgCERyXmdPxGuFvnDKB/getUserRole/' +
                ownerId
        );
        const data = await res.json();
        return data.userRole;
    } catch (e: any) {
        logError(String(e.message));
        throw new Error(e.message);
    }
}

export async function handleChangeUserPassword({
    uid,
    newPassword,
}: {
    uid: string;
    newPassword: string;
}) {
    try {
        // create a reference to the back end https function
        const changeUserPassword = httpsCallable(
            functions,
            'changeUserPassword'
        );
        const result = await changeUserPassword({ uid, newPassword });
        return result.data as string;
    } catch (e: any) {
        logError(String(e.message));
        throw new Error(e.message);
    }
}

export async function handleOnCallCreateStripeAcc({
    name,
    email,
}: {
    name: string;
    email: string;
}) {
    try {
        // create a reference to the back end https function
        const onCallCreateStripeAcc = httpsCallable(
            functions,
            'onCallCreateStripeAcc'
        );
        const result = await onCallCreateStripeAcc({ name, email });
        return result.data as string;
    } catch (e: any) {
        logError(String(e.message));
        throw new Error(e.message);
    }
}

export async function handleOnCallGenerateAppleWallet(
    cardName: string,
    email: string,
    phone: string | number,
    address: string,
    allEmails: string[] | '',
    allPhones: string[] | '',
    allAddresses: string[] | '',
    jobTitle: string,
    uid: string,
    username: string,
    name: string,
    profilePictureName: string,
    cardID: string,
    foregroundColor: string,
    labelColor: string,
    backgroundColor: string
) {
    try {
        // create a reference to the back end https function
        const onCallGenerateAppleWallet = httpsCallable(
            functions,
            'onCallGenerateAppleWallet'
        );
        const result = await onCallGenerateAppleWallet({
            cardName,
            email,
            phone,
            address,
            allEmails,
            allPhones,
            allAddresses,
            jobTitle,
            uid,
            username,
            name,
            profilePictureName,
            cardID,
            foregroundColor,
            labelColor,
            backgroundColor,
        });
        return result.data as string;
    } catch (e: any) {
        logError(String(e.message));
        throw new Error(e.message);
    }
}

export async function handleOnCallGenerateVCF({
    name,
    companyName,
    jobTitle,
    profilePicture,
    vcfFileName,
    vcfFileNameBefore,
    phone,
    email,
    uid,
    allPhones,
    allEmails,
    links,
    address,
    allAddresses,
    widgets,
}: any) {
    try {
        // create a reference to the back end https function
        const onCallGenerateVCF = httpsCallable(functions, 'onCallGenerateVCF');

        const result = await onCallGenerateVCF({
            name: name || '',
            companyName: companyName || '',
            jobTitle: jobTitle || '',
            profilePicture: profilePicture || '',
            vcfFileName: vcfFileName || '',
            vcfFileNameBefore: vcfFileNameBefore || '',
            phone: phone || '',
            email: email || '',
            uid: uid || '',
            allPhones: allPhones || '',
            allEmails: allEmails || '',
            links: links || '',
            address: address || '',
            allAddresses: allAddresses || '',
            widgets: widgets || '',
        });

        return result.data as string;
    } catch (e: any) {
        logError(String(e.message));
        throw new Error(e.message);
    }
}

export async function handleOnCallDowngradeAcc(
    uid: string,
    isFromDeletedAcc?: boolean
) {
    try {
        // create a reference to the back end https function
        const onCallDowngradeAcc = httpsCallable(
            functions,
            'onCallDowngradeAcc'
        );
        const result = await onCallDowngradeAcc({
            userId: uid,
            isFromDeletedAcc,
        });
        return result.data as string;
    } catch (e: any) {
        logError(String(e.message));
        throw new Error(e.message);
    }
}

export async function handleOnCallCopyFilesOnCopyDigitalCard(
    card: SwapkaartType,
    primaryCardDocId: string
): Promise<SwapkaartType> {
    try {
        const onCallCopyFilesOnCopyDigitalCard = httpsCallable(
            functions,
            'onCallCopyFilesOnCopyDigitalCard'
        );

        const result = await onCallCopyFilesOnCopyDigitalCard({
            ...card,
            primaryCardDocId,
        });
        return result.data as SwapkaartType;
    } catch (e: any) {
        logError(String(e.message));
        throw new Error(e.message);
    }
}

export async function handleOnCallGenerateAndroidWallet(docId: string) {
    try {
        const onCallGenerateAndroidWallet = httpsCallable(
            functions,
            'onCallGenerateAndroidWallet'
        );

        const result = await onCallGenerateAndroidWallet({ docId });
        return result.data as string;
    } catch (e: any) {
        logError(String(e.message));
        throw new Error(e.message);
    }
}
