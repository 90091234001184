// Your projects firebase configuration
const firebaseConfig = {
    apiKey: 'AIzaSyAfU4vndhRnX4VomnD4x-IIetSi-KfV33g',
    authDomain: 'codebooth-a4b70.firebaseapp.com',
    databaseURL: 'https://codebooth-a4b70.firebaseio.com',
    projectId: 'codebooth-a4b70',
    storageBucket: 'codebooth-a4b70.appspot.com',
    messagingSenderId: '690489741026',
    appId: '1:690489741026:web:492e0ed79d41a3fc0018bb',
};

export default firebaseConfig;
