import { IntegrationItem } from './components/IntegrationItem';
import { PaidWarningOverlay } from '../../../components/PaidWarningOverlay/PaidWarningOverlay';
import { PAID_PLANS } from '../../../util/utils';
import { useEffect, useState } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { firestore } from '../../../util/firebaseProviders';
import { useAuth } from '../../../context/UserContext';
import Spinner from '../../../components/Loaders/Spinner';

export type Integration = {
    id: number;
    title: string;
    subTitle: string;
    link: string;
    icon: string;
};

const integrations: Integration[] = [
    {
        id: 0,
        title: 'Zapier',
        subTitle: 'Build custom automations and integrations with apps.',
        link: 'https://zapier.com/apps/swapkaart/integrations',
        icon: '/assets/Icons/Integrations/zapier.svg',
    },
];

const Integrations = () => {
    const { user } = useAuth();
    const [linkTitle, setLinkTitle] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        const checkZapier = async (uid: string) => {
            setLoading(true);
            try {
                const userDocRef = doc(firestore, 'users', uid, 'zappier', uid);
                const userDocSnapshot = await getDoc(userDocRef);

                return userDocSnapshot.exists();
            } catch (error) {
                console.error('Error reading document: ', error);
                return null;
            } finally {
                setLoading(false);
            }
        };

        if (user?.uid) {
            checkZapier(user.uid).then((res) => {
                if (res) {
                    setLinkTitle('Connected');
                } else {
                    setLinkTitle('Connect');
                }
            });
        }
    }, []);

    return (
        <PaidWarningOverlay withButton paidPlan={PAID_PLANS.BASIC}>
            <div className="w-full rounded-[12px] border-[#E9E9EB] border-[1px] p-[24px] bg-[#FFFFFF] relative">
                <div className="w-full flex items-center justify-between">
                    <div>
                        <p className="text-[16px] text-[#202020] font-medium">
                            Integrations
                        </p>
                        <p className="text-[14px] text-[#202020] mb-[24px]">
                            Here you can connect the tool you use every day.
                        </p>
                    </div>
                </div>
                <div className="flex flex-col w-full md:flex-row gap-5 flex-wrap">
                    {integrations.map((integration) => {
                        return (
                            <IntegrationItem
                                loading={loading}
                                linkTitle={linkTitle}
                                integration={integration}
                                key={integration.id}
                            />
                        );
                    })}
                </div>
            </div>
        </PaidWarningOverlay>
    );
};

export default Integrations;
