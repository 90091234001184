import firebaseConfig from './firebaseConfig';
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getFunctions } from 'firebase/functions';
// import * as firebaseAdmin from "firebase-admin";
// import serviceAccount from "./service_account.json"

const firebaseApp = initializeApp(firebaseConfig);

// // if (!firebaseAdmin.apps.length) {
// export const firebaseAdminApp = firebaseAdmin.initializeApp({
//   credential: firebaseAdmin.credential.cert(serviceAccount,'admin'),
// });
// }
// Initialize auth && firestore with the 'firebaseApp' property
export const auth = getAuth(firebaseApp);
export const firestore = getFirestore(firebaseApp);
export const storage = getStorage(firebaseApp);
export const functions = getFunctions(firebaseApp, 'europe-west1');
export const functionsWest3 = getFunctions(firebaseApp, 'europe-west3');

export default firebaseApp;
