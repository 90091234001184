import { twMerge } from 'tailwind-merge';
import { OrganizationUser } from '../Types/Settings';
import {
    getDocumentsWithWhere,
    getSingleDocument,
    updateSingleDocumentWithDocID,
} from './Firestore';
import { handleUserCustomTeamsClaims } from './Functions';
import hexRgb from 'hex-rgb';
import { UserRoleType } from '../hooks/useGetUserRole';

export function classNames(...args: any[]): string {
    return twMerge(args.filter(Boolean).join(' '));
}

export function paginate(array: any[], pageSize: number) {
    const pageCount = Math.ceil(array.length / pageSize);

    const result: any = {};
    for (let pageIndex = 0; pageIndex < pageCount; pageIndex++) {
        const startIndex = pageIndex * pageSize;
        const endIndex = startIndex + pageSize;
        const currentPage = pageIndex + 1;
        result[currentPage] = array.slice(startIndex, endIndex);
    }

    return { data: result, lastPage: pageCount };
}

export async function handleInvitedUser(
    paramsEmail: string,
    paramsOwnerId: string,
    paramsRole: string,
    userId: string,
    userName: string,
    paramsTeamOwnerId: string
) {
    const organizationUser: OrganizationUser[] | undefined =
        await getDocumentsWithWhere(
            'organizationUsers',
            [
                {
                    field: 'email',
                    operator: '==',
                    searchValue: paramsEmail,
                },
            ],
            1,
            false
        );

    if (organizationUser?.length) {
        const { docId, ownerId, accepted } = organizationUser[0];

        if (docId && (!accepted || (accepted && ownerId !== paramsOwnerId))) {
            await Promise.allSettled([
                handleUserCustomTeamsClaims({
                    uid: userId,
                    teamOwnerId: paramsTeamOwnerId,
                }),
                updateSingleDocumentWithDocID(
                    'organizationUsers',
                    {
                        name: userName,
                        ownerId: paramsOwnerId,
                        role: paramsRole,
                        userId,
                        accepted: true,
                        firstLogin: new Date(),
                        lastLogin: new Date(),
                        teamOwnerId: paramsTeamOwnerId,
                    },
                    docId
                ),
            ]);
        }
    }
}

export async function handleOrganizationUserLogin(
    email: string,
    setPermissions: (permissions: string[]) => void
) {
    const organizationUser: OrganizationUser[] | undefined =
        await getDocumentsWithWhere(
            'organizationUsers',
            [
                {
                    field: 'email',
                    operator: '==',
                    searchValue: email,
                },
            ],
            1,
            false
        );

    if (organizationUser?.length) {
        const { docId, ownerId, role } = organizationUser[0];

        if (docId) {
            await updateSingleDocumentWithDocID(
                'organizationUsers',
                {
                    lastLogin: new Date(),
                },
                docId
            );
        }

        if (ownerId && role) {
            const organizationRoles: any = await getSingleDocument(
                'organizationRoles',
                ownerId
            );
            setPermissions(organizationRoles[role]);
        }
    }
}

export const templateBackgroundPositionMap = {
    'object-center': 'center center',
    'object-bottom': 'bottom center',
    'object-top': 'top center',
    'object-left': 'center left',
    'object-left-bottom': 'bottom left',
    'object-left-top': 'top left',
    'object-right': 'center right',
    'object-right-bottom': 'bottom right',
    'object-right-top': 'top right',
};

export const getEmbedUrl = (youtubeUrl: string) => {
    const videoIdMatch = youtubeUrl.match(
        /(?:\?v=|\/embed\/|\/watch\?v=|youtu\.be\/|\/shorts\/)([\w\d_-]+)/i
    );

    if (videoIdMatch) {
        const videoId = videoIdMatch[1];
        const embedUrl = `https://www.youtube.com/embed/${videoId}`;
        return embedUrl;
    } else {
        return '';
    }
};

export const validateLink = (url: string) => {
    const urlPattern = /^(https?:\/\/)?([\w.-]+)\.([a-z]{2,})(\/\S*)?$/i;

    if (!urlPattern.test(url)) {
        return false;
    }

    return true;
};

export const customizeOldIconsConverter: { [key: string]: string } = {
    '': 'customLink',
    website: 'customLink',
    facebook: 'facebook',
    instagram: 'instagram',
    twitter: 'twitter',
    whatsapp: 'whatsapp',
    linkedin: 'linkedin',
    addressUrl: 'customLink',
    tikTok: 'tikTok',
    telegram: 'telegram',
    viberGroup: 'viber',
    spotify: 'spotify',
    snapchat: 'snapchat',
};

export const capitalizeFirstLetter = (str: any) => {
    if (typeof str !== 'string' || str.length === 0) {
        return str;
    }

    return str.charAt(0).toUpperCase() + str.slice(1);
};

export const hexToRgbString = (hex: string) => {
    const { red, green, blue } = hexRgb(hex);
    return `rgb(${red},${green},${blue})`;
};

export enum PAID_PLANS {
    BASIC = 'basic',
    PRO = 'subscribed',
    TEAM = 'team',
    BASIC_AND_PRO = 'basic_and_subscribed',
    BASIC_AND_TEAM = 'basic_and_team',
    PRO_AND_TEAM = 'subscribed_and_team',
    ALL = 'all',
}

export const isPaidPlan = (
    subscription: UserRoleType | null,
    requiredPlans?: PAID_PLANS
): boolean => {
    if (!subscription || !requiredPlans) return false;

    const planMap = {
        [PAID_PLANS.BASIC]: ['basic'],
        [PAID_PLANS.PRO]: ['subscribed'],
        [PAID_PLANS.TEAM]: ['team'],
        [PAID_PLANS.BASIC_AND_PRO]: ['basic', 'subscribed'],
        [PAID_PLANS.BASIC_AND_TEAM]: ['basic', 'team'],
        [PAID_PLANS.PRO_AND_TEAM]: ['subscribed', 'team'],
        [PAID_PLANS.ALL]: ['basic', 'subscribed', 'team'],
    };

    const subscriptionPlans = planMap[subscription];
    const requiredPlanList = planMap[requiredPlans];

    return requiredPlanList.some((plan) => subscriptionPlans.includes(plan));
};
