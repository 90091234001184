import { SwapkaartType } from '../../Types/Swapkaart';

type ACTIONSTYPE =
    | { type: 'LOADING_CARDS'; payload: boolean }
    | { type: 'LOADING_COPY_CARD'; payload: boolean }
    | { type: 'HANDLE_CARD_DELETE'; payload: string }
    | { type: 'HANDLE_TEAMS_CARD_DELETE'; payload: string }
    | { type: 'SET_ALL_CARDS'; payload: SwapkaartType[] }
    | { type: 'SET_ALL_TEAM_CARDS'; payload: SwapkaartType[] }
    | { type: 'ADD_TO_CARDS'; payload: SwapkaartType }
    | { type: 'UPDATE_CARD_DATA'; payload: SwapkaartType }
    | {
          type: 'SET_DEFAULT_CARD';
          payload: {
              currDefaultCard: SwapkaartType;
              updatedDefaultCard: SwapkaartType;
          };
      };

export const cardsReducer = (
    state: {
        cards: SwapkaartType[] | null;
        teamCards: SwapkaartType[] | null;
        loading: boolean;
        loadingCopyCard: boolean;
    },
    actions: ACTIONSTYPE
) => {
    switch (actions.type) {
        case 'LOADING_CARDS':
            return { ...state, loading: actions.payload };
        case 'LOADING_COPY_CARD':
            return { ...state, loadingCopyCard: actions.payload };
        case 'HANDLE_CARD_DELETE':
            return {
                ...state,
                cards:
                    state.cards &&
                    state.cards.filter(
                        (card) => card.docId !== actions.payload
                    ),
                loading: false,
            };
        case 'HANDLE_TEAMS_CARD_DELETE':
            return {
                ...state,
                teamCards:
                    state.teamCards &&
                    state.teamCards.filter(
                        (card) => card.docId !== actions.payload
                    ),
                loading: false,
            };
        case 'SET_ALL_CARDS':
            return {
                ...state,
                loading: false,
                cards: actions.payload,
            };
        case 'SET_ALL_TEAM_CARDS':
            return {
                ...state,
                loading: false,
                teamCards: actions.payload,
            };
        case 'ADD_TO_CARDS':
            return {
                ...state,
                cards: state.cards && [...state.cards, actions.payload],
                loading: false,
            };
        case 'UPDATE_CARD_DATA':
            return {
                ...state,
                cards:
                    state.cards &&
                    state.cards.map((card) => {
                        if (card.docId === actions.payload.docId) {
                            return actions.payload;
                        } else {
                            return card;
                        }
                    }),
                loading: false,
            };
        case 'SET_DEFAULT_CARD':
            return {
                ...state,
                cards:
                    state.cards &&
                    state.cards.map((card) => {
                        if (
                            card.docId === actions.payload.currDefaultCard.docId
                        ) {
                            return actions.payload.currDefaultCard;
                        } else if (
                            card.docId ===
                            actions.payload.updatedDefaultCard.docId
                        ) {
                            return actions.payload.updatedDefaultCard;
                        } else {
                            return card;
                        }
                    }),
                loading: false,
            };
        default:
            throw new Error('No such action');
    }
};
