import { deleteObject, listAll, ref, getDownloadURL } from 'firebase/storage';
import { storage } from './firebaseProviders';
import { logError } from './Firestore';

export async function deletePicture(path: string, fileName: string) {
    const spaceRef = ref(storage, `${path}/${fileName}`);

    // Delete the file
    try {
        await deleteObject(spaceRef);
        return 'success';
    } catch (error: any) {
        logError(String(error.message));
        console.log(error);
        throw new Error(error);
    }
}

// download ulr

export async function deleteFolder(path: string, folderId: string) {
    const folderRef = ref(storage, `${path}/${folderId}`);
    try {
        const allFiles = await listAll(folderRef);
        allFiles.items.forEach(async (item) => {
            const fileRef = ref(storage, item.fullPath);
            try {
                await deleteObject(fileRef);
            } catch (e: any) {
                logError(String(e.message));
                throw new Error(e);
            }
        });
    } catch (e: any) {
        logError(String(e.message));
        throw new Error(e);
    }
}

export async function deleteFile(path: string, fileName: string) {
    const spaceRef = ref(storage, `${path}/${fileName}`);

    // Delete the file
    try {
        await deleteObject(spaceRef);
        return 'success';
    } catch (error: any) {
        logError(String(error.message));
        console.log(error);
        throw new Error(error);
    }
}

export async function downloadFile(url: string | undefined, fileName: string) {
    if (!url) {
        throw new Error('URL is undefined');
    }

    try {
        const spaceRef = ref(storage, url);
        const downloadUrl = await getDownloadURL(spaceRef);

        const xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.onload = () => {
            const blob = xhr.response;

            const a = document.createElement('a');
            a.href = URL.createObjectURL(blob);
            a.download = fileName;
            a.style.display = 'none';
            document.body.appendChild(a);
            a.click();

            URL.revokeObjectURL(a.href);
            document.body.removeChild(a);
        };
        xhr.open('GET', downloadUrl);
        xhr.send();
    } catch (error: any) {
        logError(String(error.message));
        console.log(error);
        throw new Error(error);
    }
}
