import React, {
    useEffect,
    useState,
    useCallback,
    useContext,
    useMemo,
} from 'react';
import { CheckCircleIcon } from '@heroicons/react/24/outline';
import { SwitchToggle } from '../../components/Buttons/SwitchToggle';
import { useAuth } from '../../context/UserContext';
import Select from '../../components/Select/Select';
import { Button } from '../../ui-lib/components/Button/Button';
import Spinner from '../../components/Loaders/Spinner';
import { InitialStateType } from '.';
import {
    TEAMS_PLANS,
    UserLimitationStateContext,
} from '../../context/UserLimitationContext';
import { Link } from 'react-router-dom';

export type SubscriptionCardsDataType = {
    title: string;
    subTitle?: string;
    priceMonthly: number | number[];
    priceYearly: number | number[];
    priceTag: string;
    priceIdMonthly: string | string[];
    priceIdYearly: string | string[];
    subPrice?: string;
    options: string[];
    trailPeriod?: number;
    footnote?: string;
    optional: string;
    link: string;
    button: string;
};

const defaultStyle = 'border middle:hover:border-blue-400 rounded';
const selectedStyle = '!border !border-blue-400 !bg-blue-500/10';
const mobileVisibleOptions = 6;

const selectOptions = [
    { value: '0', name: '0 - 10 digital cards' },
    { value: '1', name: '0 - 25 digital cards' },
    { value: '2', name: '0 - 50 digital cards' },
    { value: '3', name: '0 - 100 digital cards' },
    { value: '4', name: '0 - 250 digital cards' },
    { value: '5', name: '251+ digital cards' },
];

const SubscriptionCards = ({
    subscriptionCardsData,
    selectedPayment,
    setSelectedPayment,
    isYearly,
    setIsYearly,
    cardsPriceRangeIndex,
    setCardsPriceRangeIndex,
    initialPaidPlan,
    setInitialPaidPlan,
    isSubscriptionCardBlocked,
    setIsSubscriptionCardBlocked,
    isAccountBlocked,
}: {
    subscriptionCardsData: SubscriptionCardsDataType[];
    selectedPayment: SubscriptionCardsDataType;
    setSelectedPayment: React.Dispatch<
        React.SetStateAction<SubscriptionCardsDataType>
    >;
    isYearly: boolean;
    setIsYearly: React.Dispatch<React.SetStateAction<boolean>>;
    cardsPriceRangeIndex: number;
    setCardsPriceRangeIndex: React.Dispatch<React.SetStateAction<number>>;
    initialPaidPlan: InitialStateType;
    setInitialPaidPlan: React.Dispatch<React.SetStateAction<InitialStateType>>;
    isSubscriptionCardBlocked: boolean;
    setIsSubscriptionCardBlocked: React.Dispatch<React.SetStateAction<boolean>>;
    isAccountBlocked: boolean;
}) => {
    const { subscription, user, getSubscription } = useAuth();
    const { userPriceId, loadingFetchingPlan, subscriptionPlanData } =
        useContext(UserLimitationStateContext);
    const [visible, setVisible] = useState<string[]>([]);
    const [paidPrice, setPaidPrice] = useState<{
        paidPrice: number;
        period: string;
    }>();
    const [period, setPeriod] = useState<string>();
    const [selectedOption, setSelectedOption] = useState(selectOptions[0]);
    const [isShowInitialPrice, setIsShowInitialPrice] = useState<boolean>(true);

    useEffect(() => {
        if (!subscription) {
            const fetchSubscription = async () => {
                try {
                    getSubscription();
                } catch (error) {
                    console.error('Error fetching subscription:', error);
                }
            };
            fetchSubscription();
        }
    }, [getSubscription, subscription]);

    //Change subscription options based on releaseDateV3
    const modifiedSubscriptionCardsData = useMemo(() => {
        return subscriptionCardsData.map((card) => {
            if (card.title === 'Basic' && subscription === 'basic') {
                return {
                    ...card,
                    options: card.options.map((option) =>
                        option === 'Unique digital card'
                            ? !isAccountBlocked
                                ? 'Create up to 10 digital cards'
                                : 'Unique digital card'
                            : option
                    ),
                };
            } else if (card.title === 'Pro' && subscription === 'subscribed') {
                return {
                    ...card,
                    options: card.options.map((option) =>
                        option === '2 digital cards'
                            ? !isAccountBlocked && isSubscriptionCardBlocked
                                ? 'Unlimited digital cards'
                                : '2 digital cards'
                            : option
                    ),
                };
            }
            return card;
        });
    }, [
        subscription,
        subscriptionCardsData,
        isAccountBlocked,
        isSubscriptionCardBlocked,
    ]);

    const teamsPlanMap = Object.values(TEAMS_PLANS).filter(
        (value) => typeof value === 'number'
    );

    const handleSeeAll = useCallback((id: string) => {
        setVisible((prevVisible) => {
            const newVisible = prevVisible.includes(id)
                ? prevVisible.filter((cardId) => cardId !== id)
                : [...prevVisible, id];
            document
                .getElementById(id)
                ?.classList.toggle('hidden', !newVisible.includes(id));
            document
                .getElementById(id)
                ?.classList.toggle('flex', newVisible.includes(id));
            return newVisible;
        });
    }, []);

    const blockCurrentPlanCardStyles = useCallback(
        (cardTitle: string) => {
            const isBadgeDisplayed = currentPlanDisplayBadge(cardTitle);

            if (subscription === 'basic' && cardTitle === 'Basic') {
                return 'cursor-pointer';
            }

            if (isBadgeDisplayed) {
                return isSubscriptionCardBlocked
                    ? 'pointer-events-none bg-gray-300/30 middle:hover:border-gray-100'
                    : 'cursor-pointer';
            }

            return 'cursor-pointer';
        },
        [subscription, isSubscriptionCardBlocked]
    );

    const currentPlanDisplayBadge = useCallback(
        (cardTitle: string) => {
            switch (subscription) {
                case 'basic':
                    return cardTitle === 'Basic';
                case 'team':
                    return cardTitle === 'Teams';
                case 'subscribed':
                    return cardTitle === 'Pro';
                default:
                    return false;
            }
        },
        [subscription]
    );

    const handleRedirect = useCallback(() => {
        window.open(
            'https://www.swapkaart.com/for-businesses#wf-form-For-companies',
            '_blank'
        );
    }, []);

    const findPlanIndex = useCallback(() => {
        if (userPriceId) {
            for (const card of modifiedSubscriptionCardsData) {
                if (card.title === 'Teams') {
                    const monthlyIndex = Array.isArray(card.priceIdMonthly)
                        ? card.priceIdMonthly.indexOf(userPriceId)
                        : -1;

                    if (monthlyIndex !== -1) {
                        setPaidPrice({
                            paidPrice: (card.priceMonthly as number[])[
                                monthlyIndex
                            ],
                            period: 'per month',
                        });
                        setPeriod('per month');
                        setSelectedOption(selectOptions[monthlyIndex]);
                        setCardsPriceRangeIndex(monthlyIndex);
                        setInitialPaidPlan({
                            isYearly: false,
                            initialOption: selectOptions[monthlyIndex],
                        });

                        return;
                    }

                    const yearlyIndex = Array.isArray(card.priceIdYearly)
                        ? card.priceIdYearly.indexOf(userPriceId)
                        : -1;

                    if (yearlyIndex !== -1) {
                        setPaidPrice({
                            paidPrice: (card.priceYearly as number[])[
                                yearlyIndex
                            ],
                            period: 'per year',
                        });
                        setPeriod('per year');
                        setSelectedOption(selectOptions[yearlyIndex]);
                        setCardsPriceRangeIndex(yearlyIndex);
                        setIsYearly(true);
                        setInitialPaidPlan({
                            isYearly: true,
                            initialOption: selectOptions[yearlyIndex],
                        });

                        return;
                    }
                } else if (card.title === 'Pro') {
                    if (card.priceIdMonthly === userPriceId) {
                        setPaidPrice({
                            paidPrice: card.priceMonthly as number,
                            period: 'per month',
                        });
                        setPeriod('per month');
                        setInitialPaidPlan({
                            isYearly: false,
                            initialOption: null,
                        });
                        return;
                    }

                    if (card.priceIdYearly === userPriceId) {
                        setPaidPrice({
                            paidPrice: card.priceYearly as number,
                            period: 'per year',
                        });
                        setPeriod('per year');
                        setIsYearly(true);
                        setInitialPaidPlan({
                            isYearly: true,
                            initialOption: null,
                        });
                        return;
                    }
                } else if (
                    (subscription === 'team' && !isAccountBlocked) ||
                    (subscription === 'team' &&
                        subscriptionPlanData?.metadata.card_limitation)
                ) {
                    // set default values for team accounts before V3 and custom plans

                    const quantity = subscriptionPlanData?.quantity;
                    const amount =
                        subscriptionPlanData?.items?.[0].price.unit_amount;

                    const isDefaultYearly =
                        subscriptionPlanData?.items?.[0].price.recurring
                            .interval === 'year'
                            ? true
                            : false;

                    setPaidPrice({
                        paidPrice: +((amount * quantity) / 100).toFixed(2),
                        period: isDefaultYearly ? 'per year' : 'per month',
                    });
                    setPeriod(isDefaultYearly ? 'per year' : 'per month');
                    setSelectedOption(selectOptions[0]);
                    setCardsPriceRangeIndex(0);
                    setIsYearly(isDefaultYearly);
                    setInitialPaidPlan({
                        isYearly: isDefaultYearly,
                        initialOption: selectOptions[0],
                    });

                    return;
                }
            }
        } else {
            // Fallback: set default values
            setPaidPrice(undefined);
            setPeriod('per month');
            setSelectedOption(selectOptions[0]);
            setCardsPriceRangeIndex(0);
            setInitialPaidPlan({
                isYearly: false,
                initialOption: selectOptions[0],
            });
        }
    }, [
        userPriceId,
        subscriptionCardsData,
        selectOptions,
        setPeriod,
        setSelectedOption,
        setPaidPrice,
        isAccountBlocked,
        subscriptionPlanData,
    ]);

    useEffect(() => {
        if (
            user &&
            (subscription === 'team' || subscription === 'subscribed')
        ) {
            findPlanIndex();
        }
    }, [userPriceId, subscriptionCardsData, subscription, findPlanIndex]);

    const getPrice = useCallback(
        (card: SubscriptionCardsDataType) => {
            let price: number;
            let pricePerCard = 0;

            if (card.priceTag) {
                return [card.priceTag];
            }

            if (
                card.title === 'Pro' &&
                paidPrice &&
                isShowInitialPrice &&
                subscription === 'subscribed'
            ) {
                if (card.priceIdMonthly === userPriceId) {
                    price = card.priceMonthly as number;
                } else if (card.priceIdYearly === userPriceId) {
                    price = card.priceYearly as number;
                } else {
                    price = isYearly
                        ? (card.priceYearly as number)
                        : (card.priceMonthly as number);
                }
            } else if (
                card.title === 'Teams' &&
                paidPrice &&
                isShowInitialPrice &&
                subscription === 'team'
            ) {
                price = paidPrice.paidPrice;
                pricePerCard =
                    price / (teamsPlanMap[+selectedOption.value] as number);
            } else if (
                Array.isArray(card.priceMonthly) &&
                Array.isArray(card.priceYearly)
            ) {
                if (
                    // teams custom plans
                    card.title === 'Teams' &&
                    subscription === 'team' &&
                    paidPrice &&
                    isSubscriptionCardBlocked &&
                    subscriptionPlanData?.metadata.card_limitation
                ) {
                    const quantity = subscriptionPlanData?.quantity;
                    const amount =
                        subscriptionPlanData?.items?.[0].price.unit_amount;
                    price = +((amount * quantity) / 100).toFixed(2);
                } else if (
                    card.title === 'Teams' &&
                    subscription === 'team' &&
                    !isAccountBlocked &&
                    isSubscriptionCardBlocked
                ) {
                    // set values for team accounts before V3
                    const quantity = subscriptionPlanData?.quantity;
                    const amount =
                        subscriptionPlanData?.items?.[0].price.unit_amount;
                    price = +((amount * quantity) / 100).toFixed(2);

                    //set price for old canceled teams account
                    if (isNaN(price)) {
                        price = 0.0;
                    }
                } else {
                    price = (isYearly ? card.priceYearly : card.priceMonthly)[
                        cardsPriceRangeIndex
                    ];
                    pricePerCard =
                        price / (teamsPlanMap[+selectedOption.value] as number);

                    //set price for old canceled teams account
                    if (isNaN(price)) {
                        price = 0.0;
                    }
                }
            } else {
                price = isYearly
                    ? (card.priceYearly as number)
                    : (card.priceMonthly as number);
                pricePerCard =
                    price / (teamsPlanMap[+selectedOption.value] as number);
            }

            return [`€${price.toFixed(2)}`, `€${pricePerCard.toFixed(2)}`];
        },
        [
            isYearly,
            cardsPriceRangeIndex,
            paidPrice,
            isShowInitialPrice,
            userPriceId,
            subscription,
        ]
    );

    const getPeriod = useCallback(
        (card: SubscriptionCardsDataType) => {
            let currentPeriod: string;
            if (card.title === 'Teams' && period && subscription === 'team') {
                currentPeriod = period;
            } else if (
                card.title === 'Pro' &&
                period &&
                subscription === 'subscribed'
            ) {
                currentPeriod = period;
            } else currentPeriod = isYearly ? 'per year' : 'per month';

            return currentPeriod;
        },
        [isYearly, period]
    );

    useEffect(() => {
        setPeriod(isYearly ? 'per year' : 'per month');
    }, [isYearly]);

    const handleSelectOnChange = useCallback(
        (option: { value: string; name: string }) => {
            if (option.value !== selectedOption.value) {
                setSelectedOption(option);
                setCardsPriceRangeIndex(parseInt(option.value));
                setIsShowInitialPrice(false);
                if (subscription === 'team') {
                    setIsSubscriptionCardBlocked(false);
                }
                const teamsCard = modifiedSubscriptionCardsData.find(
                    (card) => card.title === 'Teams'
                );

                if (teamsCard) {
                    setSelectedPayment(teamsCard);
                }

                if (
                    option.value === initialPaidPlan?.initialOption?.value &&
                    isYearly === initialPaidPlan.isYearly
                ) {
                    setIsSubscriptionCardBlocked(true);
                }
            }
        },
        [
            selectedOption,
            setCardsPriceRangeIndex,
            setSelectedPayment,
            isYearly,
            initialPaidPlan,
        ]
    );

    const renderCardOptions = (
        card: SubscriptionCardsDataType,
        cardIndex: number
    ) => {
        return card.options
            .slice(
                0,
                (card.title === 'Teams'
                    ? !isAccountBlocked &&
                      subscription === 'team' &&
                      isSubscriptionCardBlocked
                        ? 6
                        : 3
                    : mobileVisibleOptions) + 1
            )
            .map((option, optionIndex) => (
                <span
                    className="flex gap-4 text-body2/regular items-center"
                    key={`${cardIndex}-${optionIndex}-${card.title}`}
                >
                    <CheckCircleIcon
                        className="w-5 h-5"
                        aria-hidden="true"
                        fill="none"
                    />
                    {option}
                </span>
            ));
    };

    const getVisibleOptionsCount = (
        title: string,
        isAccountBlocked: boolean,
        isSubscriptionCardBlocked: boolean,
        mobileVisibleOptions: number
    ) => {
        if (title === 'Teams') {
            if (
                !isAccountBlocked &&
                subscription === 'team' &&
                isSubscriptionCardBlocked
            ) {
                return 6;
            }
            return 3;
        }
        return mobileVisibleOptions;
    };

    const renderSeeAllButton = (
        card: SubscriptionCardsDataType,
        cardIndex: number
    ) => {
        const visibleOptionsCount = getVisibleOptionsCount(
            card.title,
            isAccountBlocked,
            isSubscriptionCardBlocked,
            mobileVisibleOptions
        );

        return (
            card.options.length > visibleOptionsCount + 1 && (
                <span
                    onClick={() => handleSeeAll(`${card.title}${cardIndex}`)}
                    className="flex flex-col text-blue-500 pointer-events-auto cursor-pointer"
                >
                    {!visible.includes(`${card.title}${cardIndex}`)
                        ? `Read more`
                        : 'Collapse'}
                </span>
            )
        );
    };

    const handleSetIsYearly = () => {
        setIsYearly(!isYearly);
        setIsShowInitialPrice(false);

        if (
            subscription === 'team' &&
            initialPaidPlan?.isYearly !== isYearly &&
            initialPaidPlan!.initialOption === selectedOption
        ) {
            setIsSubscriptionCardBlocked(true);
        } else if (subscription === 'team') {
            setIsSubscriptionCardBlocked(false);
            setSelectedPayment(modifiedSubscriptionCardsData[2]);
        }

        if (
            subscription === 'subscribed' &&
            initialPaidPlan?.isYearly !== isYearly
        ) {
            setIsSubscriptionCardBlocked(true);
        } else if (subscription === 'subscribed') {
            setIsSubscriptionCardBlocked(false);
            setSelectedPayment(modifiedSubscriptionCardsData[1]);
        }
    };

    useEffect(() => {
        if (
            isYearly === initialPaidPlan?.isYearly &&
            subscription === 'team' &&
            selectedOption.value === initialPaidPlan?.initialOption?.value &&
            selectedPayment.title === 'Teams'
        ) {
            setSelectedPayment({} as SubscriptionCardsDataType);
        }

        if (
            isYearly === initialPaidPlan?.isYearly &&
            subscription === 'subscribed' &&
            selectedPayment.title === 'Pro'
        ) {
            setSelectedPayment({} as SubscriptionCardsDataType);
        }
    }, [isYearly, selectedPayment, selectedOption]);

    return (
        <>
            <div className="relative mt-8">
                <div className="flex">
                    <div className="flex gap-3 items-center z-10 min-w-[250px]">
                        <div className="text-body1/regular">Pay Monthly</div>
                        <SwitchToggle
                            enabled={isYearly}
                            handleToggleSwitch={handleSetIsYearly}
                            srText="Pay Monthly"
                        />
                        <div>Pay Yearly</div>
                    </div>
                    <img
                        src="/assets/Discount-Arrow.svg"
                        alt="Arrow"
                        className="hidden lg:block absolute left-[100px] -top-[27px]"
                    />
                    <img
                        src="/assets/Icons/arrow-down.svg"
                        alt="Arrow"
                        className="block lg:hidden absolute left-[190px] -top-[25px]"
                    />
                    <span className="absolute lg:top-[23px] lg:left-[360px] left-[50px] -top-[30px] lg:text-left text-blue-500 font-medium min-w-[140px]">
                        2 months for free
                    </span>
                </div>
            </div>
            <div className="flex lg:justify-center gap-6 w-full  overflow-auto  p-4 mb-20 middle:mb-0">
                {modifiedSubscriptionCardsData.map((card, cardIndex) => {
                    const blockedStyles = blockCurrentPlanCardStyles(
                        card.title
                    );

                    const isCurrentPlan = currentPlanDisplayBadge(card.title);

                    const handleClick =
                        isCurrentPlan &&
                        card.title !== 'Basic' &&
                        isSubscriptionCardBlocked
                            ? undefined
                            : () => setSelectedPayment(card);

                    return (
                        <div key={card.title} onClick={handleClick}>
                            <div
                                className={`flex flex-col gap-y-0 w-[18.875rem]  py-10 px-6 shadow-md rounded-xl border-gray-100 ${blockedStyles} ${
                                    card.title === selectedPayment.title
                                        ? selectedStyle
                                        : defaultStyle
                                }`}
                            >
                                <div className="flex justify-between items-center text-h3 mb-2">
                                    <h2>{card.title}</h2>
                                    {isCurrentPlan && (
                                        <span className="flex justify-center items-center text-blue-500 border border-blue-500 rounded-full py-1 px-3 text-body2/regular">
                                            <span>Current plan</span>
                                        </span>
                                    )}
                                </div>
                                <h3 className="text-gray-400 text-body2/regular h-16">
                                    {card.subTitle}
                                </h3>
                                <>
                                    {loadingFetchingPlan &&
                                    card.title === 'Teams' &&
                                    subscription === 'team' ? (
                                        <div className="flex justify-center items-center h-[143px]">
                                            <Spinner />
                                        </div>
                                    ) : loadingFetchingPlan &&
                                      card.title === 'Pro' &&
                                      subscription === 'subscribed' ? (
                                        <div className="flex justify-center items-center h-[143px]">
                                            <Spinner />
                                        </div>
                                    ) : (
                                        <>
                                            <div className="flex gap-1 mb-4 text-body1/regular w-[17rem] h-18">
                                                {card.title === 'Teams' &&
                                                cardsPriceRangeIndex === 5 ? (
                                                    <Button
                                                        className="pointer-events-auto my-[15px]"
                                                        type={'button'}
                                                        onClick={handleRedirect}
                                                    >
                                                        Get a quote
                                                    </Button>
                                                ) : (
                                                    <>
                                                        <div className="flex flex-col ">
                                                            <div className="flex items-center">
                                                                <h1 className="text-h leading-none">
                                                                    {
                                                                        getPrice(
                                                                            card
                                                                        )[0]
                                                                    }
                                                                </h1>
                                                                {card.priceMonthly !==
                                                                    0 && (
                                                                    <span>
                                                                        /{' '}
                                                                        {getPeriod(
                                                                            card
                                                                        )}
                                                                    </span>
                                                                )}
                                                            </div>
                                                            {card.title ===
                                                                'Teams' &&
                                                                (subscription !==
                                                                    'team' ||
                                                                    (subscription ===
                                                                        'team' &&
                                                                        !(
                                                                            !isAccountBlocked &&
                                                                            isSubscriptionCardBlocked
                                                                        ))) && (
                                                                    <div className="text-gray-400 text-body2/regular my-2">
                                                                        {
                                                                            getPrice(
                                                                                card
                                                                            )[1]
                                                                        }{' '}
                                                                        per card{' '}
                                                                        {getPeriod(
                                                                            card
                                                                        )}
                                                                    </div>
                                                                )}
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                            {card.title === 'Teams' &&
                                                (isSubscriptionCardBlocked &&
                                                subscriptionPlanData?.metadata
                                                    .card_limitation ? (
                                                    <span className="mb-2.5">
                                                        To change the current
                                                        plan{' '}
                                                        <a
                                                            href="https://www.swapkaart.com/contact-us"
                                                            className="text-blue-500 pointer-events-auto"
                                                            target="_blank"
                                                        >
                                                            contact us
                                                        </a>
                                                    </span>
                                                ) : (
                                                    (subscription !== 'team' ||
                                                        (subscription ===
                                                            'team' &&
                                                            !(
                                                                !isAccountBlocked &&
                                                                isSubscriptionCardBlocked
                                                            ))) && (
                                                        <div className="pointer-events-auto h-[3.7rem]">
                                                            <Select
                                                                key={
                                                                    selectedOption.value
                                                                }
                                                                options={
                                                                    selectOptions
                                                                }
                                                                onChange={
                                                                    handleSelectOnChange
                                                                }
                                                                initValue={
                                                                    selectedOption
                                                                }
                                                            />
                                                        </div>
                                                    )
                                                ))}
                                        </>
                                    )}
                                </>
                                <h2 className="text-gray-400 text-body1/regular mb-3">
                                    {card.subPrice}
                                </h2>
                                <div className="flex flex-col gap-y-3 mb-5">
                                    {renderCardOptions(card, cardIndex)}
                                    <div
                                        className="flex-col gap-y-3 hidden"
                                        id={`${card.title}${cardIndex}`}
                                    >
                                        {card.options
                                            .slice(
                                                getVisibleOptionsCount(
                                                    card.title,
                                                    isAccountBlocked,
                                                    isSubscriptionCardBlocked,
                                                    mobileVisibleOptions
                                                ) + 1
                                            )
                                            .map((option, optionIndex) => (
                                                <span
                                                    className="flex gap-4 text-body2/regular items-center"
                                                    key={`${cardIndex}-${
                                                        optionIndex +
                                                        getVisibleOptionsCount(
                                                            card.title,
                                                            isAccountBlocked,
                                                            isSubscriptionCardBlocked,
                                                            mobileVisibleOptions
                                                        ) +
                                                        1
                                                    }-${card.title}`}
                                                >
                                                    <CheckCircleIcon
                                                        className="w-5 h-5"
                                                        aria-hidden="true"
                                                        fill="none"
                                                    />
                                                    {option}
                                                </span>
                                            ))}
                                        <div className="text-body2/regular mt-5 mb-2">
                                            <div className="font-semibold">
                                                Optional:
                                            </div>
                                            <div className="mb-2">
                                                <span>{card.optional}</span>
                                            </div>
                                            <Link
                                                to={card.link}
                                                className="text-body2/regular mt-auto text-blue-500 pointer-events-auto"
                                                target="_blank"
                                            >
                                                {card.button}
                                            </Link>
                                        </div>
                                    </div>
                                    {renderSeeAllButton(card, cardIndex)}
                                </div>

                                <span className="text-body2/regular text-blue-500">
                                    {card.footnote}
                                </span>
                            </div>
                        </div>
                    );
                })}
            </div>
        </>
    );
};

export default SubscriptionCards;
