import {
    CheckCircleIcon,
    ExclamationCircleIcon,
    InformationCircleIcon,
    XMarkIcon,
} from '@heroicons/react/24/outline';
import { motion } from 'framer-motion';

export const Toast = ({
    text,
    type = 'info',

    onClose,
}: {
    text: string;
    type: 'info' | 'confirm' | 'alert';
    onClose?: () => void;
}) => {
    return (
        <div className="bg-white rounded-md ">
            <div className="flex items-center px-4 py-2 my-2">
                <div className="flex-shrink-0">
                    {type === 'alert' ? (
                        <ExclamationCircleIcon className="w-5 h-5 text-red-500" />
                    ) : type === 'info' ? (
                        <InformationCircleIcon
                            className={`w-5 h-5 
                                     text-blue-500`}
                            aria-hidden="true"
                        />
                    ) : (
                        <CheckCircleIcon
                            className="w-6 h-6 text-white"
                            aria-hidden="true"
                            fill="rgb(34 197 94)"
                        />
                    )}
                </div>
                <div className="ml-3">
                    <p
                        className={`text-sm font-medium ${
                            type === 'info'
                                ? 'text-blue-500'
                                : type === 'confirm'
                                ? 'text-green-800'
                                : 'text-red-500'
                        }`}
                    >
                        {text}
                    </p>
                </div>

                <div className="pl-3 ml-auto">
                    <div className="-mx-1.5 ">
                        <motion.button
                            style={{ WebkitTapHighlightColor: 'transparent' }}
                            onClick={onClose}
                            whileTap={{ scale: 0.9 }}
                            type="button"
                            className={`inline-flex   rounded-md p-1.5 ${
                                type === 'info'
                                    ? '  bg-blue-50 text-blue-500 hover:bg-blue-100  focus:ring-offset-blue-50 focus:ring-blue-600'
                                    : type === 'confirm'
                                    ? 'bg-green-50  text-green-500 hover:bg-green-100  focus:ring-offset-green-50 focus:ring-green-600'
                                    : 'bg-red-5 text-red-500 hover:bg-red-100   focus:ring-offset-red-50 focus:ring-red-600'
                            } focus:outline-none `}
                        >
                            <span className="sr-only">Dismiss</span>
                            <XMarkIcon className="w-4 h-4" aria-hidden="true" />
                        </motion.button>
                    </div>
                </div>
            </div>
        </div>
    );
};
