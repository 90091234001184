import { motion } from 'framer-motion';
import { forwardRef, ReactNode, Ref } from 'react';

type PropsType = {
    children: ReactNode;
    type: 'button' | 'submit';
    className?: string;
    form?: string;
    disabled?: boolean;
    onClick?: (e?: any) => void;
    onSubmit?: (e?: any) => void;
};
// eslint-disable-next-line react/display-name
const PrimaryButton = forwardRef(
    (props: PropsType, ref: Ref<HTMLButtonElement>) => {
        return (
            <>
                <motion.button
                    whileTap={!props.disabled ? { scale: 0.9 } : {}}
                    style={{
                        WebkitTapHighlightColor: 'transparent',
                    }}
                    ref={ref}
                    {...props}
                    className={
                        props.disabled
                            ? `${props.className} shrink-0 transition-colors outline-none duration-300 relative px-6 py-2 cursor-not-allowed text-center text-sm  text-white bg-[#BBD1FC]  rounded-[6px]   shadow-sm  font-medium`
                            : `${props.className} shrink-0 transition-colors outline-none duration-300  px-6 py-2 text-center text-sm  text-white hover:bg-blue-500/80 bg-[#2C71F6]  rounded-[6px]   shadow-sm font-medium`
                    }
                >
                    {props.children}
                </motion.button>
            </>
        );
    }
);

export default PrimaryButton;
