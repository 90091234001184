import {
    browserSessionPersistence,
    createUserWithEmailAndPassword,
    onAuthStateChanged,
    setPersistence,
    updateProfile,
    User,
} from 'firebase/auth';
import {
    createContext,
    ReactNode,
    useCallback,
    useContext,
    useEffect,
    useReducer,
    useRef,
} from 'react';
import { useLocation, useNavigate } from 'react-router';
import { ToastPortal, ToastType } from '../../components/Toast/ToastPortal';
import { defaultSwapkaartValues } from '../../constants/swapkaartData';
import { PreOrderedCardType } from '../../Types/PreOrderedCard';
import {
    emailSignIn,
    getUserClaims,
    getUserRole,
    googleSignIn,
    signOutUser,
    updateUserData,
} from '../../util/Auth';
import { auth } from '../../util/firebaseProviders';
import {
    getDocumentsWithWhere,
    logError,
    sendEmailWithTemplate,
    updateSingleDocumentWithDocID,
    writeSingleDocumentWithDocID,
} from '../../util/Firestore';
import {
    handleOnCallCreateStripeAcc,
    handleUserCustomClaims,
} from '../../util/Functions';
import { useGlobalLoadingState } from '../GlobalLoadingContext';
import { userReducer } from './userReducer';
import { UserRoleType } from '../../hooks/useGetUserRole';
import { IOrganizationUser } from '../../pages/Settings/Teams/Teams';
import { handleInvitedUser } from '../../util/utils';
import { useSearchParams } from 'react-router-dom';

type ContextType = {
    user: User | null;
    permissions: string[] | null;
    isOrganizationUser: boolean | null;
    loadingUser: boolean;
    subscription: UserRoleType | null;
    role: IOrganizationUser['role'] | null;
    teamOwnerId: string | null;
    singInUser: (
        email: string,
        password: string,
        rememberme: boolean
    ) => Promise<User | null>;
    signInWithGoogle: () => void;
    createUser: (
        email: string,
        password: string,
        name: string,
        rememberme: boolean
    ) => Promise<User | null>;
    logoutUser: () => Promise<void>;
    logoutDeletedUser: () => Promise<void>;
    setPermissions: (permissions: string[]) => void;
    updateUserDetails: (userData: {
        displayName: string;
        photoURL: string;
        email: string;
        oldPassword?: string;
        newPassword?: string;
    }) => Promise<void>;
    handleActivatingPhysicalCard: (
        preOrderedCard: PreOrderedCardType,
        user: User
    ) => Promise<void>;
    getSubscription: () => void;
};

export type locationStateProps = {
    preOrderedCard: PreOrderedCardType;
};

export const UserContext = createContext({} as ContextType);

export const useAuth = () => useContext(UserContext);

const UserProvider = ({ children }: { children: ReactNode }) => {
    const [state, dispatch] = useReducer(userReducer, {
        user: null,
        permissions: null,
        isOrganizationUser: null,
        loadingUser: true,
        subscription: null,
        role: null,
        teamOwnerId: null,
    });

    const navigate = useNavigate();
    const toastRef = useRef<ToastType>();
    const location = useLocation();
    const locationState = location.state as locationStateProps;
    const { setGlobalLoading } = useGlobalLoadingState();
    const logoutUser = useCallback(async () => {
        dispatch({ type: 'LOADING_USER', payload: true });
        await signOutUser();
        dispatch({ type: 'LOGOUT_USER' });
        navigate('/login', { replace: true });
        window.location.reload();
    }, [navigate]);

    const logoutDeletedUser = useCallback(async () => {
        dispatch({ type: 'LOADING_USER', payload: true });
        await signOutUser();
        dispatch({ type: 'LOGOUT_USER' });
        navigate('/register', { replace: true });
    }, [navigate]);

    const setPermissions = (permission: string[]) => {
        dispatch({ type: 'SET_PERMISSIONS', payload: permission });
    };

    const [searchParams] = useSearchParams();
    const paramsEmail = searchParams.get('email');
    const paramsRole = searchParams.get('role');
    const paramsOwnerId = searchParams.get('ownerId');
    const paramsTeamOwnerId = searchParams.get('teamOwnerId');

    //zapier OAuth v2
    const clientId = searchParams.get('client_id');
    const zapierState = searchParams.get('state');
    const redirectUri = searchParams.get('redirect_uri');
    const responseType = searchParams.get('response_type');

    const singInUser = useCallback(
        async (email: string, password: string, rememberme: boolean) => {
            try {
                if (!rememberme)
                    setPersistence(auth, browserSessionPersistence);

                const data = await emailSignIn(email, password);
                if (data) {
                    if (locationState) {
                        await handleActivatingPhysicalCard(
                            locationState.preOrderedCard,
                            data.user
                        );
                    }
                    dispatch({
                        type: 'LOGIN_USER_WITH_EMAIL_AND_PASSWORD',
                        payload: data.user,
                    });
                    return data.user;
                } else {
                    return null;
                }
            } catch (e: any) {
                dispatch({ type: 'LOADING_USER', payload: false });
                throw new Error(e);
            }
        },
        [navigate, dispatch]
    );

    const updateUserDetails = useCallback(async (userData: any) => {
        try {
            const updatedUser = await updateUserData(userData);
            if (updatedUser) {
                dispatch({
                    type: 'UPDATE_USER_DETAILS',
                    payload: updatedUser,
                });
                toastRef.current?.addToast({
                    message: 'Changes saved!',
                    type: 'info',
                    position: 'bottom-right',
                });
            }
        } catch (error: any) {
            throw new Error(error);
        }
    }, []);

    const createUser = useCallback(
        async (
            email: string,
            password: string,
            name: string,
            rememberme: boolean
        ) => {
            dispatch({ type: 'LOADING_USER', payload: true });
            try {
                if (!rememberme) {
                    setPersistence(auth, browserSessionPersistence);
                }
                const data = await createUserWithEmailAndPassword(
                    auth,
                    email,
                    password
                );

                if (data) {
                    await Promise.allSettled([
                        updateProfile(data.user, {
                            displayName: name,
                        }),
                        sendEmailWithTemplate(
                            'newCustomerWithoutACard',
                            {
                                cta1: 'https://app.swapkaart.com/login',
                                cta2: 'https://app.swapkaart.com/login',
                            },
                            email
                        ),
                        //  sendEmailVerification(data.user);
                        handleUserCustomClaims({
                            uid: data.user.uid,
                        }),
                    ]);

                    if (locationState) {
                        await handleActivatingPhysicalCard(
                            locationState.preOrderedCard,
                            data.user
                        );
                    }
                    dispatch({
                        type: 'CREATE_USER',
                        payload: data.user,
                    });
                    return data.user;
                } else {
                    return null;
                }
            } catch (e: any) {
                dispatch({ type: 'LOADING_USER', payload: false });
                throw new Error(e);
            }
        },
        [navigate, dispatch]
    );

    const signInWithGoogle = useCallback(async () => {
        setGlobalLoading(true);
        dispatch({ type: 'LOADING_USER', payload: true });
        try {
            const data = await googleSignIn();
            if (data) {
                if (locationState) {
                    handleActivatingPhysicalCard(
                        locationState.preOrderedCard,
                        data
                    );
                }
                if (
                    data?.uid &&
                    data?.displayName &&
                    paramsRole &&
                    paramsOwnerId &&
                    paramsEmail &&
                    paramsTeamOwnerId
                ) {
                    await handleInvitedUser(
                        paramsEmail,
                        paramsOwnerId,
                        paramsRole,
                        data.uid,
                        data.displayName,
                        paramsTeamOwnerId
                    ).then(() => window.location.reload());
                } else {
                    try {
                        const existingUser = await getDocumentsWithWhere(
                            'users',
                            [
                                {
                                    field: 'email',
                                    operator: '==',
                                    searchValue: data.email || '',
                                },
                            ],
                            1,
                            false
                        );
                        if (existingUser && existingUser.length === 0) {
                            await handleOnCallCreateStripeAcc({
                                name: data.displayName || '',
                                email: data.email || '',
                            });
                        }
                    } catch (error) {
                        console.error(error);
                    }
                }

                //zapier login
                try {
                    try {
                        if (
                            data?.uid &&
                            clientId &&
                            zapierState &&
                            redirectUri &&
                            responseType
                        ) {
                            const params = new URLSearchParams();
                            params.append('client_id', clientId);
                            params.append('state', zapierState);
                            params.append('redirect_uri', redirectUri);
                            params.append('response_type', responseType);

                            const urlParams = params.toString();

                            navigate(`/oauth?${urlParams}`);
                        }
                    } catch (error) {
                        console.log(error);
                    }
                } catch (error) {
                    console.log(error);
                }

                dispatch({
                    type: 'SIGN_IN_USER_WITH_GOOGLE',
                    payload: data,
                });
            }
        } catch (e: any) {
            dispatch({ type: 'LOADING_USER', payload: false });
            throw new Error(e);
        }
        setGlobalLoading(false);
    }, [navigate, state.user]);

    const handleActivatingPhysicalCard = async (
        preOrderedCard: PreOrderedCardType,
        user: User
    ) => {
        const payload = {
            ...defaultSwapkaartValues,
            productID: preOrderedCard.productID,
            productName: preOrderedCard.productName,
        };
        payload['ownerId'] = user.uid;
        payload['default'] = true;
        payload['name'] = user?.displayName ?? '';

        payload['username'] = preOrderedCard.docId;
        payload['docId'] = preOrderedCard.docId;

        try {
            const result = await Promise.allSettled([
                writeSingleDocumentWithDocID(
                    'swapkaart',
                    {
                        ...payload,
                    },
                    preOrderedCard.docId
                ),
                updateSingleDocumentWithDocID(
                    'pre-ordered-cards',
                    {
                        ...preOrderedCard,
                        activated: true,
                    },
                    preOrderedCard.docId
                ),
            ]);
            setGlobalLoading(false);
            if (result) {
                toastRef.current?.addToast({
                    message: 'Card activated successfully',
                    position: 'top-right',
                    type: 'confirm',
                });
                navigate('/customize/edit/' + payload.docId);
            }
        } catch (e: any) {
            setGlobalLoading(false);

            throw new Error(e.message);
        }
    };

    const getSubscription = () => {
        if (state.user) {
            getUserRole()
                .then((data) => {
                    if (data) {
                        dispatch({
                            type: 'SET_TYPE_SUBSCRIPTION',
                            payload: data,
                        });
                    } else {
                        //set subscription for old accounts without userRole
                        dispatch({
                            type: 'SET_TYPE_SUBSCRIPTION',
                            payload: 'basic',
                        });
                    }
                })
                .catch((error) => {
                    console.error('Error getting user role:', error);
                });
        }
    };

    useEffect(() => {
        if (state.user) return;
        // firebase check if user is sign in or not on auth change
        const unsubscribe = onAuthStateChanged(auth, async (currUser) => {
            try {
                if (currUser) {
                    dispatch({
                        type: 'UPDATE_USER_DETAILS',
                        payload: currUser,
                    });
                } else {
                    dispatch({ type: 'LOADING_USER', payload: false });
                }
            } catch (e: any) {
                throw new Error(e);
            }
        });

        return () => unsubscribe();
    }, []);

    useEffect(() => {
        if (state.user && state.isOrganizationUser === null) {
            getUserClaims().then((res) => {
                dispatch({
                    type: 'SET_IS_ORGANIZATION_USER',
                    payload: !!res?.ownerId,
                });
            });
        } else if (!state.user && state.isOrganizationUser !== null) {
            dispatch({
                type: 'SET_IS_ORGANIZATION_USER',
                payload: null,
            });
        }
    }, [state.user]);

    useEffect(() => {
        if (state.user) {
            getUserRole().then((data) => {
                dispatch({
                    type: 'SET_TYPE_SUBSCRIPTION',
                    payload: data,
                });
            });
        }
    }, [state.user]);

    useEffect(() => {
        (async () => {
            try {
                if (state.user) {
                    const userRole = await getUserRole();

                    if (userRole === 'team') {
                        const data = await getDocumentsWithWhere<any>(
                            'organizationUsers',
                            [
                                {
                                    field: 'email',
                                    operator: '==',
                                    searchValue: state!.user!.email!,
                                },
                            ],
                            1,
                            false
                        );
                        if (data && data.length > 0) {
                            dispatch({
                                type: 'SET_USER_ROLE',
                                payload: data[0].role,
                            });
                            dispatch({
                                type: 'SET_TEAM_OWNER_ID',
                                payload: data[0].teamOwnerId,
                            });
                        } else {
                            dispatch({
                                type: 'SET_TEAM_OWNER_ID',
                                payload: state.user.uid,
                            });
                        }
                    }
                }
            } catch (e: any) {
                logError(String(e.message));
                throw new Error(e.message);
            }
        })();
    }, [state.user, state.teamOwnerId]);

    const values = {
        user: state.user,
        permissions: state.permissions,
        isOrganizationUser: state.isOrganizationUser,
        loadingUser: state.loadingUser,
        subscription: state.subscription,
        role: state.role,
        teamOwnerId: state.teamOwnerId,
        createUser,
        signInWithGoogle,
        singInUser,
        logoutUser,
        logoutDeletedUser,
        updateUserDetails,
        handleActivatingPhysicalCard,
        setPermissions,
        getSubscription,
    };

    return (
        <UserContext.Provider value={values}>
            {children}
            <ToastPortal
                autoClose
                autoCloseTimeout={3000}
                position="top-right"
                ref={toastRef}
            />
        </UserContext.Provider>
    );
};

export default UserProvider;
