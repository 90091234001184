import PrimaryButton from '../Buttons/PrimaryButton';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { useNavigate } from 'react-router';

interface SuccessCanceledPlanModalType {
    isOpen: boolean;
    onClose: () => void;
    closeModal: () => void;
}

export const SuccessCanceledPlanModal = ({
    onClose,
    closeModal,
    isOpen,
}: SuccessCanceledPlanModalType) => {
    const navigate = useNavigate();

    const handelCloseModal = () => {
        closeModal();
        navigate('/');
    };
    return (
        <>
            <Transition appear show={isOpen} as={Fragment}>
                <Dialog
                    as="div"
                    className="relative z-30"
                    onClick={(e: any) => e.stopPropagation()}
                    onClose={closeModal}
                >
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 z-30 transition-opacity bg-gray-500 bg-opacity-75" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-30 overflow-y-auto">
                        <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="relative flex flex-col items-center justify-center px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:max-w-lg sm:w-full sm:p-6 sm:pt-0">
                                    <div className="w-full flex justify-center items-center">
                                        <img
                                            src="/assets/gif/success.gif"
                                            alt="Success"
                                        />
                                    </div>

                                    <div className="text-center sm:mt-0 mb-2">
                                        <Dialog.Title
                                            as="h3"
                                            className="text-body1/medium font-medium leading-6 text-gray-900"
                                        >
                                            Plan downgraded successfully!
                                        </Dialog.Title>
                                    </div>
                                    <div className="flex items-center justify-center sm:mt-0 w-full mb-1">
                                        <div className="text-center">
                                            <p className="text-body2/regular font-medium leading-6 text-gray-500">
                                                You have successfully downgraded
                                                your plan to Basic plan. Your
                                                current plan will be active till
                                                the end of the billing period.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="mt-5 sm:mt-4">
                                        <PrimaryButton
                                            onClick={handelCloseModal}
                                            type="button"
                                            className="flex items-center px-[10px] sm:px-[20px] h-[38px] bg-[#2C71F6] rounded-[6px] whitespace-nowrap"
                                        >
                                            Go to dashboard
                                        </PrimaryButton>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    );
};
