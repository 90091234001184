import { AnimatePresence, motion } from 'framer-motion';
import { forwardRef, useImperativeHandle, useState } from 'react';
import ReactDOM from 'react-dom';
import { v4 } from 'uuid';
import { useToastAutoclose } from '../../hooks/useToastAutoclose';
import { useToastPortal } from '../../hooks/useToastPortal';
import { Toast } from '../Toast/';

type Props = {
    position?: 'top-right' | 'top-left' | 'bottom-right' | 'bottom-left';
    autoCloseTimeout?: number;
    autoClose?: boolean;
};

export type ToastType = {
    addToast(toast: {
        message: string;
        type: 'info' | 'confirm' | 'alert';
        position: 'top-right' | 'top-left' | 'bottom-right' | 'bottom-left';
    }): void;
};
// eslint-disable-next-line react/display-name
const ToastPortal = forwardRef(
    ({ position = 'top-right', autoCloseTimeout, autoClose }: Props, ref) => {
        const { loaded, portalId } = useToastPortal(position);
        const [toasts, setToasts] = useState<
            {
                id: string;
                message: string;
                type: 'info' | 'confirm' | 'alert';

                // onCloase: () => void;
            }[]
        >([]);
        useToastAutoclose({
            toasts,
            setToasts,
            autoClose,
            autoCloseTimeout,
        });
        const removeToast = (id: string) => {
            setToasts(toasts.filter((t) => t.id !== id));
        };

        useImperativeHandle(ref, () => ({
            addToast(toast: {
                message: string;
                type: 'info' | 'confirm' | 'alert';
            }) {
                setToasts([...toasts, { ...toast, id: v4() }]);
            },
        }));

        return loaded ? (
            ReactDOM.createPortal(
                <AnimatePresence mode="sync">
                    {position.startsWith('top')
                        ? toasts.map((t) => (
                              <motion.div
                                  layout
                                  key={t.id}
                                  initial={{
                                      opacity: 0,
                                      x: position.includes('left') ? -200 : 200,
                                  }}
                                  animate={{
                                      opacity: 1,
                                      x: 0,
                                  }}
                                  transition={{
                                      opacity: { duration: 0.2 },
                                      x: { duration: 0.4 },
                                  }}
                                  exit={{
                                      opacity: 0,
                                      x: position.includes('left') ? -200 : 200,

                                      transition: {
                                          opacity: { duration: 0.5 },
                                          x: { duration: 1 },
                                      },
                                  }}
                              >
                                  <Toast
                                      text={t.message}
                                      type={t.type}
                                      onClose={() => removeToast(t.id)}
                                  />
                              </motion.div>
                          ))
                        : toasts
                              .map((t) => (
                                  <motion.div
                                      layout
                                      key={t.id}
                                      initial={{
                                          opacity: 0,
                                          x: position.includes('left')
                                              ? -200
                                              : 200,
                                      }}
                                      animate={{
                                          opacity: 1,
                                          x: 0,
                                      }}
                                      transition={{
                                          opacity: { duration: 0.8 },
                                          x: { duration: 1 },
                                      }}
                                      exit={{
                                          opacity: 0,
                                          x: position.includes('left')
                                              ? -200
                                              : 200,

                                          transition: {
                                              opacity: { duration: 0.5 },
                                              x: { duration: 1 },
                                          },
                                      }}
                                  >
                                      <Toast
                                          text={t.message}
                                          type={t.type}
                                          onClose={() => removeToast(t.id)}
                                      />
                                  </motion.div>
                              ))
                              .reverse()}
                </AnimatePresence>,
                document.getElementById(portalId)!
            )
        ) : (
            <></>
        );
    }
);

export { ToastPortal };
