import { Dialog } from '@mui/material';
import PrimaryButton from '../Buttons/PrimaryButton';
import { ExclamationCircleIcon } from '@heroicons/react/24/solid';
import { Spinner } from '../../ui-lib/components/Spinner/Spinner';

const lockedFeatures: Record<string, string[]> = {
    proToBasic: [
        '1 digital card. If more, 1+ will be locked.',
        'Reset the card design to the default theme.',
        'Reset card design colors to default.',
        'Erase the widgets.',
        'Erase attachments.',
        'Lock the Apple Wallet.',
        'Lock the Google Wallet.',
        'Lock the export CSV contacts feature.',
        'Lock the Zapier integration feature.',
    ],
    teamsToBasic: [
        '1 digital card. If more, 1+ will be locked.',
        'Only 1 user.',
        'Reset the card design to the default theme.',
        'Reset card design colors to default.',
        'Erase the widgets.',
        'Erase attachments.',
        'Lock the Apple Wallet.',
        'Lock the Google Wallet.',
        'Lock the export CSV contacts feature.',
        'Lock the Zapier integration feature.',
        'Lock User management.',
        'Lock User roles and rights.',
        'Lock teams contacts.',
        'Lock Excel bulk import.',
        'Set teams digital accounts to basic.',
        '1 teams digital card. If more, 1+ will be locked.',
        'Reset the teams card design to the default theme.',
        'Reset the teams card design colors to default.',
        'Erase the teams widgets.',
        'Erase team attachments.',
        'Lock the Zapier integration feature for teams accounts.',
        'Lock the Apple Wallet for teams accounts.',
        'Lock the Google Wallet for teams accounts.',
        'Lock the export CSV contacts feature for teams accounts.',
    ],
    teamsToPro: [
        '2 digital cards. If more, 2+ will be locked.',
        'Only 1 user.',
        'Lock User management.',
        'Lock User roles and rights.',
        'Lock teams digital cards.',
        'Lock teams contacts.',
        'Lock Excel bulk import.',
        'Set teams digital accounts to basic.',
        'Reset the teams card design to the default theme.',
        'Reset the teams card design colors to default.',
        'Erase the teams widgets.',
        'Erase the teams attachments.',
        'Lock the Zapier integration feature for teams accounts.',
        'Lock the Apple Wallet for teams accounts.',
        'Lock the Google Wallet for teams accounts.',
        'Lock the export CSV contacts feature for teams accounts.',
    ],
};

interface DowngradeModalProps {
    isOpen: boolean;
    onClose: () => void;
    closeModal: () => void;
    handleDbRequest: () => void;
    loading: boolean;
    fromSubscription: string;
    toSubscription: string;
}

export const DowngradeModal = ({
    onClose,
    closeModal,
    isOpen,
    handleDbRequest,
    loading,
    fromSubscription,
    toSubscription,
}: DowngradeModalProps) => {
    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            sx={{
                '& .MuiDialog-paper': {
                    minWidth: '512px',
                    minHeight: '240px',
                    borderRadius: '12px',
                    padding: '24px',

                    '@media (max-width: 600px)': {
                        minWidth: '90%',
                    },
                },
                '& .MuiDialogContent-root': {
                    padding: 0,
                },
            }}
        >
            <div>
                <div className="w-full flex justify-center items-center mb-[24px]">
                    <ExclamationCircleIcon className="w-[56px] h-[56px] fill-red-500" />
                </div>

                <p className="text-[16px] text-[#202020] text-center font-medium mb-[8px]">
                    Are you sure you want to downgrade?
                </p>

                <p className="text-[14px] text-[#89898A] text-center mb-[24px]">
                    Downgrading to {toSubscription} plan will remove all{' '}
                    {fromSubscription} features and may result in data loss if
                    your digital business cards exceed the limits of your plan
                </p>

                {fromSubscription && toSubscription && (
                    <div className="mt-[24px] flex flex-col items-center">
                        <p className="text-[16px] text-[#202020] text-center font-medium mb-[8px]">
                            Impacts:
                        </p>
                        <ul className="text-[14px] text-[#89898A] list-disc list-inside mt-3 mb-8">
                            {lockedFeatures[
                                `${fromSubscription.toLowerCase()}To${toSubscription}`
                            ].map((feature, index) => (
                                <li key={index}>{feature}</li>
                            ))}
                        </ul>
                    </div>
                )}
                <div className="w-full flex flex-col middle:flex-row items-center gap-[12px]">
                    <button
                        onClick={closeModal}
                        type="button"
                        className="flex items-center justify-center w-full middle:w-[50%] h-[38px] gap-[12px] rounded-[6px] 
                                   bg-[#FFFFFF] border-[1px] border-[#D8D8DA] text-[#202020] text-[14px] font-semibold"
                    >
                        Cancel
                    </button>

                    <PrimaryButton
                        onClick={handleDbRequest}
                        type="submit"
                        form="edit-form"
                        className="flex items-center justify-center shrink-0 w-full middle:w-[50%] h-[38px] rounded-[6px] bg-[#2C71F6] 
                                 disabled:text-white disabled:bg-[#BBD1FC]"
                    >
                        {loading ? (
                            <Spinner className="border-[#2C71F6]" />
                        ) : (
                            'Downgrade'
                        )}
                    </PrimaryButton>
                </div>
            </div>
        </Dialog>
    );
};
