import { SwapkaartType } from '../Types/Swapkaart';

export const defaultSwapkaartValues: SwapkaartType = {
    address: '',
    addressLink: '',
    title: '',
    companyName: '',
    userNameChanged: false,
    default: false,
    coverImage: { alt: '', url: '', position: 'object-center' },
    description: '',
    ownerId: '',
    email: '',
    name: '',
    // vcfLink: '',
    jobTitle: '',
    links: [],
    downloadableFiles: [],
    phone: '',
    profilePicture: { url: '', alt: '', position: 'object-center' },
    username: '',
    // btn1Color: '',
    // btn2Color: '',
    // textColor: '',
    // cardBG: '',

    timestamp: 0,
};
