import {
    addDoc,
    collection,
    collectionGroup,
    CollectionReference,
    deleteDoc,
    doc,
    DocumentData,
    DocumentSnapshot,
    FieldValue,
    getDoc,
    getDocs,
    getFirestore,
    limit,
    orderBy,
    query,
    Query,
    serverTimestamp,
    setDoc,
    startAfter,
    Timestamp,
    updateDoc,
    where,
} from 'firebase/firestore';
import { firestore } from './firebaseProviders';
// import omit from "lodash/omit";
const db = getFirestore();
export const firebaseServerTimestap = serverTimestamp();
// firestore collection references for the users
// const colRef = collection(db, "users");

export async function logError(error: any) {
    try {
        addDoc(collection(firestore, 'errorLog'), {
            errorMessage: { error },
            timestamp: firebaseServerTimestap,
        });
        return 'success';
    } catch (error) {
        console.log(error);
        throw new Error('Error in writing document to Firebase');
    }
}

export function customPostToJSONForPage(doc: any) {
    const data = doc.data();

    if (doc.exists()) {
        const payload = {
            ...data,
            docId: doc.id,
            timestamp: data?.timestamp?.toMillis() || 0,
        };

        return payload;
    }
}

// Write Single Document with a specific ID as document ID.

/**
 * @name writeSingleDocumentWithDocID
 * @description Random hashing algorithm I found on Stack Overflow.
 * @param {string} str
 * @param {boolean} asString
 * @param {*} seed
 *
 * @returns {string} hash
 */

export async function writeSingleDocumentWithDocID<T>(
    collection: string,
    payload: T & { timestamp?: FieldValue },
    docID: string
) {
    try {
        payload['timestamp'] = firebaseServerTimestap;
        await setDoc(doc(firestore, collection, docID), payload, {
            merge: true,
        });
        return docID;
    } catch (error: any) {
        logError(String(error.message));
        console.log(error);
        throw new Error(error.message);
    }
}

export async function sendEmail(emailData: {
    to: string;
    message: {
        subject: string;
        html: string;
    };
}) {
    try {
        const triggerEmail = await addDoc(
            collection(firestore, 'mail'),
            emailData
        );
        // const triggerEmail = await db.collection("mail").add(emailData);
        return triggerEmail;
    } catch (error: any) {
        logError(String(error.message));
        console.log(error);
        return error;
    }
}

/**
 * @name sendEmailWithTemplate
 * @description Sends an email from firebase with a pre-defined template. To send to multiple emails add the addresses to an array
 * @param {string} templateId
 * @param {} data
 * @param {string | Array<string>} to
 *
 *
 * @returns {} {
            operationSuccess: {boolean},
            message: `email was sent sucesfully`
        };
 */

export async function sendEmailWithTemplate<T>(
    templateId: string,
    data: T,
    to: string | Array<string>
) {
    try {
        const triggerEmail = await addDoc(collection(firestore, 'mail'), {
            to: to,
            template: {
                name: templateId,
                data: data,
            },
        });
        return {
            operationSuccess: true,
            message: `email was sent sucesfully`,
        };
    } catch (error: any) {
        logError(String(error.message));
        console.log(error);
        throw new Error(String(error));
    }
}

// Write Single Document
export async function writeSingleDocument<T>(
    collectionReference: string,
    payload: T & { timestamp?: FieldValue } & { username?: string }
) {
    try {
        payload['timestamp'] = firebaseServerTimestap;
        const collectionRef = collection(firestore, collectionReference);
        const docRef = doc(collectionRef);
        await setDoc(docRef, {
            ...payload,
            docId: docRef.id,
        });
        return docRef.id;
    } catch (error: any) {
        logError(String(error.message));
        console.log(error.message);
        throw new Error(error.message);
    }
}
// Write Single Document
export async function getDocumentID<T>(collectionReference: string) {
    try {
        const collectionRef = collection(firestore, collectionReference);
        const docRef = doc(collectionRef);

        return docRef.id;
    } catch (error: any) {
        logError(String(error.message));
        console.log(error.message);
        throw new Error(error.message);
    }
}

// Update Single Document
export async function updateSingleDocumentWithDocID<T>(
    collection: string,
    payload: T & { timestamp?: FieldValue },
    docID: string
) {
    try {
        payload['timestamp'] = firebaseServerTimestap;
        await updateDoc(doc(firestore, collection, docID), payload);
        return 'success';
    } catch (error: any) {
        logError(String(error.message));
        console.log(error);
        throw new Error(error.message);
    }
}

//delete Document with Where
export async function deleteDocumentWithWhere(
    collectionReference: string,
    docId: string[]
) {
    const collectionRef = collection(firestore, collectionReference);
    const promises: Promise<void>[] = [];

    for (const statement of docId) {
        promises.push(deleteDoc(doc(collectionRef, statement)));
    }

    try {
        const results = await Promise.allSettled(promises);
        const errors: string[] = [];

        results.forEach((result) => {
            if (result.status === 'rejected') {
                const errorMessage = `${result.reason}`;
                logError(errorMessage);
                errors.push(errorMessage);
            }
        });

        if (errors.length > 0) {
            return `\n${errors.join('\n')}`;
        } else {
            return 'success';
        }
    } catch (e: any) {
        logError(String(e.message));
        throw new Error(e);
    }
}

// Get Documents with Where
export async function getDocumentsWithWhere<T>(
    collectionToQuery: string,
    whereStatments: Array<{
        field: string;
        operator: any;
        searchValue: string | number | boolean | Array<any>;
    }>,
    limitResults: number,
    collectionGroupQ: boolean
) {
    const collectionRef = collectionGroupQ
        ? collectionGroup(firestore, collectionToQuery)
        : collection(firestore, collectionToQuery);

    let collectionQuery: Query<DocumentData> | undefined;
    try {
        for (const statment of whereStatments) {
            collectionQuery = query(
                collectionRef,
                where(statment.field, statment.operator, statment.searchValue),
                limit(limitResults)
            );
        }
        // check if collectonQuery different then undefined
        if (collectionQuery) {
            const firebaseResults: T[] = (
                await getDocs(collectionQuery)
            ).docs.map(customPostToJSONForPage);

            return firebaseResults;
        } else return [];
    } catch (error: any) {
        logError(String(error.message));
        console.log(error);
    }
}
// Get Documents with Where and order
export async function getDocumentsWithWhereAndOrder<T>(
    collectionToQuery: string,
    whereStatments: Array<{
        field: string;
        operator: any;
        searchValue: string | number | boolean | Array<any>;
    }>,
    limitResults: number,
    orderByField: string,
    orderType: any,
    collectionGroupQ: boolean
) {
    // const collectionRef = collectionGroup(firestore, collectionToQuery)
    const collectionRef = collectionGroupQ
        ? collectionGroup(firestore, collectionToQuery)
        : collection(firestore, collectionToQuery);

    let collectionQuery = query(collectionRef);
    try {
        for (const statment of whereStatments) {
            collectionQuery = query(
                collectionQuery,
                where(statment.field, statment.operator, statment.searchValue)
            );
        }
        collectionQuery = query(
            collectionQuery,
            limit(limitResults),
            orderBy(orderByField, orderType)
        );

        const firebaseResults: T[] = (await getDocs(collectionQuery)).docs.map(
            customPostToJSONForPage
        );

        return firebaseResults;
    } catch (error: any) {
        console.log(error);

        logError(String(error.message));
        throw new Error(error);
    }
}
// Get Documents with Where and order and limit
export async function getDocumentWithOrderAndLimit<T>(
    collectionToQuery: string,
    limitResults: number,
    orderByField: string,
    orderType: any
) {
    const collectionRef = collection(firestore, collectionToQuery);

    let collectionQuery = query(collectionRef);
    try {
        collectionQuery = query(
            collectionQuery,
            limit(limitResults),
            orderBy(orderByField, orderType)
        );
        const firebaseResults: T[] = (await getDocs(collectionQuery)).docs.map(
            customPostToJSONForPage
        );

        return firebaseResults;
    } catch (error: any) {
        logError(String(error.message));
        console.log(error);
        return error;
    }
}
// Get more documents with Where and Order By
export async function getMoreDocumentsWithWhereAndOrder<T>(
    firebaseDocuments: Array<{ docId: string; timestamp: number | string }>,
    collectionToQuery: string,
    whereStatments: Array<{
        field: string;
        operator: any;
        searchValue: string | number | boolean | Array<any>;
    }>,
    limitResults: number,
    orderByField: string,
    orderType: any
) {
    const collectionRef = collection(firestore, collectionToQuery);
    const lastDocument = firebaseDocuments[firebaseDocuments.length - 1];
    const cursor =
        typeof lastDocument.timestamp === 'number'
            ? Timestamp.fromMillis(lastDocument.timestamp)
            : lastDocument.timestamp;
    let collectionQuery = query(collectionRef);
    try {
        for (const statment of whereStatments) {
            collectionQuery = query(
                collectionQuery,
                where(statment.field, statment.operator, statment.searchValue)
            );
        }
        collectionQuery = query(
            collectionQuery,
            limit(limitResults),
            orderBy(orderByField, orderType),
            startAfter(cursor)
        );
        const firebaseResults: T[] = (await getDocs(collectionQuery)).docs.map(
            customPostToJSONForPage
        );

        return firebaseResults;
    } catch (error: any) {
        logError(String(error.message));
        console.log(error);
        throw new Error(error);
    }
}

// Create document ID Reference
export function createDocumentIDRef(collectionID: string) {
    const collectionRef: CollectionReference<DocumentData> = collection(
        firestore,
        collectionID
    );
    const documentID = doc(collectionRef);
    return documentID.id;
}

//getting userDetails based on the email to prefill checkoutForm
export function document(document: string, userId: string) {
    return doc(db, document, userId);
}

export async function getSingleDocument<T>(
    collectionRef: string,
    documentReference: DocumentSnapshot<DocumentData> | string | string[]
) {
    try {
        const document = doc(db, `${collectionRef}/${documentReference}`);
        const getDocument = await getDoc(document);
        if (getDocument.exists()) {
            const documentData: T = customPostToJSONForPage(getDocument);
            // let documentData = getDocument.data();
            // documentData["docId"] = getDocument.id;

            return documentData;
        }
    } catch (error: any) {
        logError(String(error.message));
        console.log(error);
        throw new Error(error);
    }
}

export async function getAllDocuments<T>(
    documentRef: string,
    collectionGroupQ: boolean
) {
    try {
        // const collectionRef = collection(db, documentRef);
        const collectionRef = collectionGroupQ
            ? collectionGroup(db, documentRef)
            : collection(db, documentRef);
        const getDocuments = await getDocs(collectionRef);
        if (getDocuments.empty) return;

        const data: T[] = getDocuments.docs.map(customPostToJSONForPage);

        return data;
    } catch (error: any) {
        logError(String(error.message));
        console.log(error);
        throw new Error(error);
    }
}
// function adjustTimestampFields(currentData: any) {
//     const floorsData = currentData?.building?.floors;
//     const projectID = currentData?.building?.document?.code;
//     const updatedPayload: any = currentData;

//     updatedPayload.building.document.timestamp =
//         updatedPayload.building?.document.timestamp.toMillis();
//     try {
//         return updatedPayload;
//     } catch (error: any) {
//         console.log(error);
//         return false;
//     }
// }
