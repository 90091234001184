import { Listbox, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import React, { Fragment, useEffect, useState } from 'react';

function classNames(...classes: any) {
    return classes.filter(Boolean).join(' ');
}

interface IOption {
    name: string;
    value: string;
    color?: string;
}

interface SelectProps {
    options: IOption[];
    onChange: (val: IOption) => void;
    placeholder?: string;
    initValue?: IOption | null;
}

const Select: React.FC<SelectProps> = ({
    options,
    placeholder,
    onChange,
    initValue,
}) => {
    const [selected, setSelected] = useState(
        initValue
            ? initValue
            : placeholder
            ? { name: '', value: '' }
            : options[0]
    );

    useEffect(() => {
        if (selected?.value) {
            onChange(selected);
        }
    }, [selected]);
    return (
        <Listbox value={selected} onChange={setSelected}>
            {({ open }) => (
                <>
                    <Listbox.Button className="relative w-full py-2 pl-3 pr-10 text-left bg-white border border-gray-300 rounded-md shadow-sm cursor-default aria-expanded:border-[#2C71F6] sm:text-sm">
                        <span
                            className={`block truncate ${
                                !selected.name ? 'text-[#89898A]' : ''
                            }`}
                        >
                            {!selected.name ? placeholder : selected.name}
                        </span>
                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                            <ChevronDownIcon
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                            />
                        </span>
                    </Listbox.Button>
                    <div className="relative pb-6 mt-1 list-box-container">
                        <Transition
                            show={open}
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Listbox.Options className="absolute z-[99] w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-56 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                {options.map((option) => (
                                    <Listbox.Option
                                        key={option.value}
                                        className={({ active }) =>
                                            classNames(
                                                active
                                                    ? ' bg-blue-100'
                                                    : 'text-gray-900',
                                                'relative cursor-default select-none py-2 pl-3 pr-9'
                                            )
                                        }
                                        value={option}
                                    >
                                        {({ selected, active }) => (
                                            <>
                                                <div className="flex items-center">
                                                    {!!option?.color && (
                                                        <div
                                                            className="w-[8px] h-[8px] rounded-[50%]"
                                                            style={{
                                                                backgroundColor:
                                                                    option.color,
                                                            }}
                                                        ></div>
                                                    )}
                                                    <span
                                                        className={classNames(
                                                            selected
                                                                ? 'font-semibold'
                                                                : 'font-normal',
                                                            'ml-3 block truncate'
                                                        )}
                                                    >
                                                        {option.name}
                                                    </span>
                                                </div>
                                            </>
                                        )}
                                    </Listbox.Option>
                                ))}
                            </Listbox.Options>
                        </Transition>
                    </div>
                </>
            )}
        </Listbox>
    );
};

export default Select;
