import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import ScrollToTop from './components/ScrollToTop';
import GlobalLoadingContextProvider from './context/GlobalLoadingContext';
import UserProvider from './context/UserContext';
import './index.css';
import reportWebVitals from './reportWebVitals';
import RolesContextProvider from './context/RolesContext';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    // <React.StrictMode>
    <BrowserRouter>
        <GlobalLoadingContextProvider>
            <UserProvider>
                <RolesContextProvider>
                    {/* <DarkThemeProvider> */}

                    <ScrollToTop />
                    <App />

                    {/* </DarkThemeProvider> */}
                </RolesContextProvider>
            </UserProvider>
        </GlobalLoadingContextProvider>
    </BrowserRouter>
    /* </React.StrictMode> */
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
