import { ReactNode } from 'react';

function Spinner({
    children,
    className,
}: {
    children?: ReactNode;
    className?: string;
}) {
    return (
        <div
            className={`${className} w-5 animate-spin  h-5 border-[4px] border-t-gray-400  rounded-[50%] `}
        >
            {children}
        </div>
    );
}
export default Spinner;
