import { User } from 'firebase/auth';
import { UserRoleType } from '../../hooks/useGetUserRole';
import { IOrganizationUser } from '../../pages/Settings/Teams/Teams';

type ACTIONTYPES =
    | { type: 'LOGOUT_USER' }
    | { type: 'LOADING_USER'; payload: boolean }
    | { type: 'LOGIN_USER_WITH_EMAIL_AND_PASSWORD'; payload: User }
    | { type: 'UPDATE_USER_DETAILS'; payload: User | null }
    | { type: 'CREATE_USER'; payload: User | null }
    | { type: 'SIGN_IN_USER_WITH_GOOGLE'; payload: User | null }
    | { type: 'SET_PERMISSIONS'; payload: string[] | null }
    | { type: 'SET_IS_ORGANIZATION_USER'; payload: boolean | null }
    | { type: 'SET_TYPE_SUBSCRIPTION'; payload: any }
    | { type: 'SET_USER_ROLE'; payload: any }
    | { type: 'SET_TEAM_OWNER_ID'; payload: any };

export const userReducer = (
    state: {
        user: User | null;
        permissions: string[] | null;
        isOrganizationUser: boolean | null;
        loadingUser: boolean;
        subscription: UserRoleType | null;
        role: IOrganizationUser['role'] | null;
        teamOwnerId: string | null;
    },
    actions: ACTIONTYPES
) => {
    switch (actions.type) {
        case 'LOGOUT_USER':
            return {
                ...state,
                user: null,
                loadingUser: false,
            };
        case 'LOADING_USER':
            return { ...state, loadingUser: actions.payload };
        case 'LOGIN_USER_WITH_EMAIL_AND_PASSWORD':
            return { ...state, user: actions.payload, loadingUser: false };
        case 'UPDATE_USER_DETAILS':
            return { ...state, user: actions.payload, loadingUser: false };
        case 'CREATE_USER':
            return { ...state, user: actions.payload, loadingUser: false };
        case 'SIGN_IN_USER_WITH_GOOGLE':
            return { ...state, user: actions.payload, loadingUser: false };
        case 'SET_PERMISSIONS':
            return {
                ...state,
                permissions: actions.payload,
            };
        case 'SET_IS_ORGANIZATION_USER':
            return {
                ...state,
                isOrganizationUser: actions.payload,
            };
        case 'SET_TYPE_SUBSCRIPTION':
            return {
                ...state,
                subscription: actions.payload,
            };
        case 'SET_USER_ROLE':
            return {
                ...state,
                role: actions.payload,
            };
        case 'SET_TEAM_OWNER_ID':
            return {
                ...state,
                teamOwnerId: actions.payload,
            };
        default:
            throw new Error('No such actions');
    }
};
