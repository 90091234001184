import Spinner from '../Spinner';

function SuspenseSpinner({ className }: { className?: string }) {
    return (
        <div
            className={`!fixed !top-0 !left-0 !w-screen !h-screen !flex !items-center !justify-center bg-gray-500/10 !z-50 ${className}`}
        >
            <Spinner />
        </div>
    );
}
export default SuspenseSpinner;
