import { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../../context/UserContext';

const subscriptionHierarchy = ['basic', 'subscribed', 'team'];
const selectedPaymentTypeHierarchy = ['Basic', 'Pro', 'Teams', 'Enterprise'];

export const useDowngrade = (selectedPaymentType: string) => {
    const { subscription } = useContext(UserContext);
    const [isDowngraded, setIsDowngraded] = useState<boolean>(false);
    const [fromSubscription, setFromSubscription] = useState<string>('');
    const [toSubscription, setToSubscription] = useState<string>('');

    useEffect(() => {
        if (!subscription || !selectedPaymentType) {
            setIsDowngraded(false);
            return;
        }

        const currentPlanIndex = subscriptionHierarchy.indexOf(subscription);
        const selectedPaymentTypeIndex =
            selectedPaymentTypeHierarchy.indexOf(selectedPaymentType);

        setIsDowngraded(currentPlanIndex > selectedPaymentTypeIndex);
        setFromSubscription(selectedPaymentTypeHierarchy[currentPlanIndex]);
        setToSubscription(
            selectedPaymentTypeHierarchy[selectedPaymentTypeIndex]
        );
    }, [subscription, selectedPaymentType]);

    return { isDowngraded, fromSubscription, toSubscription };
};
