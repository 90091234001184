import {
    createContext,
    Dispatch,
    ReactNode,
    SetStateAction,
    useContext,
    useState,
} from 'react';

interface GlobalLoadingContextProps {
    children: ReactNode;
}

type ContextType = {
    globalLoading: boolean;
    setGlobalLoading: Dispatch<SetStateAction<boolean>>;
};

export const GlobalLoadingStateContext = createContext({} as ContextType);

export const useGlobalLoadingState = () =>
    useContext(GlobalLoadingStateContext);

const GlobalLoadingContextProvider = (props: GlobalLoadingContextProps) => {
    const { children } = props;
    const [globalLoading, setGlobalLoading] = useState(false);

    const value = {
        globalLoading,
        setGlobalLoading,
    };

    return (
        <GlobalLoadingStateContext.Provider value={value}>
            {children}
        </GlobalLoadingStateContext.Provider>
    );
};

export default GlobalLoadingContextProvider;
