import {
    createContext,
    Dispatch,
    ReactNode,
    SetStateAction,
    useContext,
    useEffect,
    useState,
} from 'react';
import { releaseDateV2, releaseDateV3 } from '../../constants/releaseDates';
import {
    getDocumentsWithWhere,
    getDocumentWithOrderAndLimit,
} from '../../util/Firestore';
import MyCardsProvider, { MyCardsContext } from '../CardsContext';
import { useAuth } from '../UserContext';
import { OrganizationUser } from '../../Types/Settings';
import { subscriptionCardsData } from '../../pages/Subscription/index';
import { SwapkaartType } from '../../Types/Swapkaart';

export enum TEAMS_PLANS {
    UP_TO_10 = 10,
    UP_TO_25 = 25,
    UP_TO_50 = 50,
    UP_TO_100 = 100,
    UP_TO_250 = 250,
    OVER_251 = 251,
}

interface UserLimitationContextProps {
    children: ReactNode;
}

type ContextType = {
    isCardsLimitation: boolean;
    isAccountBlocked: boolean;
    isUsersLimitation: boolean;
    setUsersCount: Dispatch<SetStateAction<number | undefined>>;
    userPriceId: string | null;
    subscriptionPlanData: any;
    loadingFetchingPlan: boolean;
    teamPlan: number | null;
    activeTeamCardsIdsByPlan: (string | undefined)[];
};

export const UserLimitationStateContext = createContext({} as ContextType);

export const useUserLimitationState = () =>
    useContext(UserLimitationStateContext);

const UserLimitationContext = (props: UserLimitationContextProps) => {
    const { children } = props;
    const { user, subscription, role } = useAuth();
    const { cards, teamCards } = useContext(MyCardsContext);
    const [loadingFetchingPlan, setLoadingFetchingPlan] =
        useState<boolean>(false);
    const [isCardsLimitation, setIsCardsLimitation] = useState<boolean>(false);
    const [isAccountBlocked, setIsAccountBlocked] = useState<boolean>(false);
    const [usersCount, setUsersCount] = useState<number | undefined>();
    const [isUsersLimitation, setIsUsersLimitation] = useState<boolean>(false);
    const [subscriptionPlanData, setSubscriptionPlanData] = useState<any>();
    const [userPriceId, setUserPriceId] = useState<string | null>(null);
    const [teamPlan, setTeamPlan] = useState<number | null>(null);
    const [allTeamCards, setAllTeamCards] = useState<SwapkaartType[] | null>(
        null
    );
    const [activeTeamCardsIdsByPlan, setActiveTeamCardsIdsByPlan] = useState<
        (string | undefined)[]
    >([]);
    const [lastSubscriptionDate, setLastSubscriptionDate] =
        useState<Date | null>(null);

    //Disable creating digital cards after releaseDateV2 or releaseDateV3 and base on lastSubscriptionDate
    useEffect(() => {
        if (!user || !user.metadata.creationTime || !subscription || !cards)
            return;

        if (user && user.metadata.creationTime && subscription && cards) {
            const creationDate = new Date(user.metadata.creationTime);
            const isBeforeReleaseDateV2 = creationDate >= releaseDateV2;
            const isBeforeReleaseDateV3 = creationDate >= releaseDateV3;
            const isSubscriptionAfterReleaseDateV3 =
                lastSubscriptionDate && lastSubscriptionDate >= releaseDateV3;

            let isLimitation = false;

            if (subscription === 'basic') {
                if (isBeforeReleaseDateV2 && cards.length >= 10) {
                    isLimitation = true;
                } else if (cards.length >= 1) {
                    if (
                        isBeforeReleaseDateV3 ||
                        isSubscriptionAfterReleaseDateV3
                    ) {
                        isLimitation = true;
                    }
                }
            } else if (subscription === 'subscribed' && cards.length >= 2) {
                if (isBeforeReleaseDateV3 || isSubscriptionAfterReleaseDateV3) {
                    isLimitation = true;
                }
            } else if (
                subscription === 'team' &&
                teamPlan &&
                allTeamCards &&
                teamPlan <= allTeamCards?.length
            ) {
                if (isBeforeReleaseDateV3 || isSubscriptionAfterReleaseDateV3) {
                    isLimitation = true;
                }
            }

            setIsCardsLimitation(isLimitation);
        }
    }, [user, cards, subscription, teamPlan, allTeamCards]);

    //Blocking digital cards after releaseDateV3 and based on the lastSubscriptionDate
    useEffect(() => {
        if (user && user.metadata.creationTime) {
            const creationDate = new Date(user.metadata.creationTime);
            const isBeforeReleaseDateV3 = creationDate >= releaseDateV3;
            const isSubscriptionAfterReleaseDateV3 =
                lastSubscriptionDate && lastSubscriptionDate >= releaseDateV3;

            // Block cards for the new pricing plan
            if (subscription === 'team' && teamPlan) {
                if (isBeforeReleaseDateV3 || isSubscriptionAfterReleaseDateV3) {
                    setIsAccountBlocked(true);
                } else {
                    setIsAccountBlocked(false);
                }
            } else if (subscription !== 'team') {
                // blocking cards after releaseDateV3
                if (isBeforeReleaseDateV3 || isSubscriptionAfterReleaseDateV3) {
                    setIsAccountBlocked(true);
                } else {
                    setIsAccountBlocked(false);
                }
            }
        }
    }, [user, cards, subscription, teamPlan, lastSubscriptionDate]);

    // Count how many users
    // useEffect(() => {
    //     if (subscription === 'team') {
    //         (async () => {
    //             try {
    //                 await getDocumentsWithWhere(
    //                     'organizationUsers',
    //                     [
    //                         {
    //                             field: 'ownerId',
    //                             operator: '==',
    //                             searchValue: user?.uid!,
    //                         },
    //                     ],
    //                     1000,
    //                     false
    //                 ).then((res) => {
    //                     if (res) {
    //                         setUsersCount(res.length + 1);
    //                     }
    //                 });
    //             } catch (e) {
    //                 console.error('Error fetching data:', e);
    //             }
    //         })();
    //     }
    // }, [user, subscription]);

    // useEffect(() => {
    //     subscription === 'team' && usersCount && usersCount >= 99
    //         ? setIsUsersLimitation(true)
    //         : setIsUsersLimitation(false);
    // }, [usersCount, subscription]);

    const getLastSubscriptionData = (userId: string) => {
        return getDocumentWithOrderAndLimit<any>(
            `users/${userId}/subscriptions`,
            1,
            'created',
            'desc'
        );
    };

    const findSubscriptionPlan = () => {
        if (userPriceId && subscription === 'team') {
            for (const card of subscriptionCardsData) {
                if (card.title === 'Teams') {
                    // check for custom plan
                    if (subscriptionPlanData?.metadata.card_limitation) {
                        setTeamPlan(
                            subscriptionPlanData?.metadata.card_limitation
                        );
                        return;
                    }

                    let planIndex: number | undefined;

                    if (
                        Array.isArray(card.priceIdMonthly) &&
                        card.priceIdMonthly.includes(userPriceId)
                    ) {
                        planIndex = card.priceIdMonthly.indexOf(userPriceId);
                    } else if (
                        Array.isArray(card.priceIdYearly) &&
                        card.priceIdYearly.includes(userPriceId)
                    ) {
                        planIndex = card.priceIdYearly.indexOf(userPriceId);
                    }

                    if (planIndex !== undefined && planIndex >= 0) {
                        const planValues = Object.values(TEAMS_PLANS).filter(
                            (value) => typeof value === 'number'
                        );

                        const planValue = planValues[planIndex] as number;

                        setTeamPlan(planValue);
                    } else {
                        console.log('Price ID not found in Teams plan');
                    }
                }
            }
        }
    };

    // Fetch user subscription plan data
    useEffect(() => {
        const fetchSubscriptionData = async () => {
            setLoadingFetchingPlan(true);
            try {
                if (!user?.uid) return;

                let subscriptionData;

                if (subscription === 'subscribed') {
                    subscriptionData = await getLastSubscriptionData(user.uid);
                } else if (subscription === 'team') {
                    if (role !== null) {
                        const organizationUser =
                            await getDocumentsWithWhere<OrganizationUser>(
                                'organizationUsers',
                                [
                                    {
                                        field: 'userId',
                                        operator: '==',
                                        searchValue: user.uid,
                                    },
                                ],
                                1,
                                false
                            );

                        if (organizationUser?.length) {
                            subscriptionData = await getLastSubscriptionData(
                                organizationUser[0].teamOwnerId
                            );
                        }
                    } else {
                        subscriptionData = await getLastSubscriptionData(
                            user.uid
                        );
                    }
                }

                const priceId =
                    subscriptionData?.[0]?.items?.[0]?.price?.id ?? null;

                if (
                    subscriptionData?.[0] &&
                    subscriptionData[0].status !== 'canceled'
                ) {
                    setUserPriceId(priceId);
                    setSubscriptionPlanData(subscriptionData[0]);
                    setLastSubscriptionDate(
                        new Date(subscriptionData?.[0].created.seconds * 1000)
                    );
                } else if (
                    subscriptionData?.[0] &&
                    subscriptionData[0].status === 'canceled'
                ) {
                    setLastSubscriptionDate(
                        new Date(
                            subscriptionData?.[0].current_period_end.seconds *
                                1000
                        )
                    );
                }
            } catch (e) {
                console.error('Error fetching subscription data:', e);
                setUserPriceId(null);
            } finally {
                setLoadingFetchingPlan(false);
            }
        };

        fetchSubscriptionData();
    }, [user, subscription, role]);

    useEffect(() => {
        findSubscriptionPlan();
    }, [userPriceId]);

    //set team plan blocked cards
    useEffect(() => {
        (async () => {
            try {
                if (!user || subscription !== 'team') return;

                if (user && subscription === 'team') {
                    const combinedCards = [
                        ...(cards || []),
                        ...(teamCards || []),
                    ];

                    setAllTeamCards(combinedCards);

                    const defaultCards = combinedCards.filter(
                        (card) => card.default
                    );

                    const nonDefaultCards = combinedCards.filter(
                        (card) => !card.default
                    );

                    const sortedDefaultCards = defaultCards.sort((a, b) => {
                        const dateA = new Date(a.timestamp);
                        const dateB = new Date(b.timestamp);
                        return dateB.getTime() - dateA.getTime();
                    });

                    const sortedNonDefaultCards = nonDefaultCards.sort(
                        (a, b) => {
                            const dateA = new Date(a.timestamp);
                            const dateB = new Date(b.timestamp);
                            return dateB.getTime() - dateA.getTime();
                        }
                    );

                    const sortedCards = [
                        ...sortedDefaultCards,
                        ...sortedNonDefaultCards,
                    ];

                    const docIds = sortedCards.map((card) => card.docId);

                    const limitedDocIds =
                        teamPlan != null ? docIds.slice(0, teamPlan) : docIds;

                    setActiveTeamCardsIdsByPlan(limitedDocIds);
                }
            } catch (error) {
                console.error('Error processing cards:', error);
            }
        })();
    }, [user, cards, teamCards, subscription, teamPlan]);

    const value = {
        isCardsLimitation,
        isAccountBlocked,
        isUsersLimitation,
        setUsersCount,
        userPriceId,
        subscriptionPlanData,
        loadingFetchingPlan,
        teamPlan,
        activeTeamCardsIdsByPlan,
    };

    return (
        <UserLimitationStateContext.Provider value={value}>
            {children}
        </UserLimitationStateContext.Provider>
    );
};

export const UserLimitationContextProvider = (
    props: UserLimitationContextProps
) => {
    const { children } = props;
    return (
        <MyCardsProvider>
            <UserLimitationContext>{children}</UserLimitationContext>
        </MyCardsProvider>
    );
};
