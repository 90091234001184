import { Switch } from '@headlessui/react';
import { ReactNode } from 'react';

function classNames(...classes: any) {
    return classes.filter(Boolean).join(' ');
}

type Props = {
    enabled: boolean;
    srText: string;
    handleToggleSwitch: (val: boolean) => void;
    children?: ReactNode;
    className?: string;
};

export const SwitchToggle = ({
    enabled,
    handleToggleSwitch,
    children,
}: Props) => {
    return (
        <div className="flex flex-wrap items-center gap-3">
            {children}
            <Switch
                checked={enabled}
                onChange={handleToggleSwitch}
                className="relative inline-flex items-center justify-center flex-shrink-0 w-10 h-5 rounded-full cursor-pointer group focus:outline-none "
            >
                <span className="sr-only">Use setting</span>
                <span
                    aria-hidden="true"
                    className="absolute w-full h-full bg-white rounded-md pointer-events-none dark:bg-black"
                />
                <span
                    aria-hidden="true"
                    className={classNames(
                        enabled
                            ? 'bg-blue-600'
                            : 'bg-gray-200 dark:border-black',
                        'pointer-events-none absolute h-4 w-9 mx-auto rounded-full transition-colors ease-in-out duration-200'
                    )}
                />
                <span
                    aria-hidden="true"
                    className={classNames(
                        enabled ? 'translate-x-5' : 'translate-x-0',
                        'pointer-events-none absolute left-0 inline-block h-5 w-5 border border-gray-200  dark:border-black  rounded-full bg-white shadow transform ring-0 transition-transform ease-in-out duration-200'
                    )}
                />
            </Switch>
        </div>
    );
};
