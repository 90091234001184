import {
    createContext,
    Dispatch,
    ReactNode,
    SetStateAction,
    useContext,
    useEffect,
    useState,
} from 'react';
import { IRowData } from '../../pages/Settings/Roles';
import { useAuth } from '../UserContext';
import { getSingleDocument } from '../../util/Firestore';

interface RolesContextProps {
    children: ReactNode;
}

type ContextType = {
    isLoading: boolean;
    isRolesExist: boolean;
    adminRows: IRowData[];
    setAdminRows: Dispatch<SetStateAction<IRowData[]>>;
    employeeRows: IRowData[];
    setEmployeeRows: Dispatch<SetStateAction<IRowData[]>>;
};

export const RolesStateContext = createContext({} as ContextType);

export const useRolesState = () => useContext(RolesStateContext);

const RolesContextProvider = (props: RolesContextProps) => {
    const { teamOwnerId } = useAuth();
    const { children } = props;
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isRolesExist, setIsRolesExist] = useState<boolean>(false);
    const [adminRows, setAdminRows] = useState<IRowData[]>([
        { title: 'Admin', value: '', checked: false },
        { value: 'manageDigitalCards', checked: false },
        { value: 'contacts', checked: false },
        // { value: 'shopNFCCards', checked: false },  //Uncomment after Purchases build
        { value: 'userManagement', checked: false },
        { value: 'roles', checked: false },
    ]);
    const [employeeRows, setEmployeeRows] = useState<IRowData[]>([
        { title: 'User', value: '', checked: false },
        { value: 'manageDigitalCards', checked: false },
        { value: 'contacts', checked: false },
        // { value: 'shopNFCCards', checked: false },  //Uncomment after Purchases build
        { value: 'userManagement', checked: false },
        { value: 'roles', checked: false },
    ]);

    const handleSetRoles = (
        arr: IRowData[],
        roles: string[],
        isAdmin: boolean
    ) => {
        const newArr = arr.map((item, index) => {
            if (roles.includes(item.value) && index !== 0) {
                return {
                    ...item,
                    checked: true,
                };
            }
            return item;
        });

        isAdmin ? setAdminRows(newArr) : setEmployeeRows(newArr);
    };

    useEffect(() => {
        if (teamOwnerId) {
            setIsLoading(true);
            getSingleDocument('organizationRoles', teamOwnerId)
                .then((roles: any) => {
                    if (roles) {
                        if (roles?.admin?.length) {
                            handleSetRoles(adminRows, roles.admin, true);
                        }
                        if (roles?.employee?.length) {
                            handleSetRoles(employeeRows, roles.employee, false);
                        }
                        setIsRolesExist(true);
                    }
                })
                .catch((er) => console.log(er))
                .finally(() => setIsLoading(false));
        }
    }, [teamOwnerId]);

    const value = {
        isLoading,
        isRolesExist,
        adminRows,
        employeeRows,
        setAdminRows,
        setEmployeeRows,
    };

    return (
        <RolesStateContext.Provider value={value}>
            {children}
        </RolesStateContext.Provider>
    );
};

export default RolesContextProvider;
