import { Dialog } from '@mui/material';
import PrimaryButton from '../Buttons/PrimaryButton';
import { ExclamationCircleIcon } from '@heroicons/react/24/solid';
import { Spinner } from '../../ui-lib/components/Spinner/Spinner';

interface ChangeCurrentPlanModal {
    isOpen: boolean;
    onClose: () => void;
    closeModal: () => void;
    handleDbRequest: () => void;
    loading: boolean;
}

export const ChangeCurrentPlanModal = ({
    onClose,
    closeModal,
    isOpen,
    handleDbRequest,
    loading,
}: ChangeCurrentPlanModal) => {
    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            sx={{
                '& .MuiDialog-paper': {
                    minWidth: '512px',
                    minHeight: '240px',
                    borderRadius: '12px',
                    padding: '24px',

                    '@media (max-width: 600px)': {
                        minWidth: '90%',
                    },
                },
                '& .MuiDialogContent-root': {
                    padding: 0,
                },
            }}
        >
            <div>
                <div className="w-full flex justify-center items-center mb-[24px]">
                    <ExclamationCircleIcon className="w-[56px] h-[56px] fill-red-500" />
                </div>

                <p className="text-[16px] text-[#202020] text-center font-medium mb-[50px]">
                    Are you sure you want to change the pricing model?
                </p>

                <div className="w-full flex flex-col middle:flex-row items-center gap-[12px]">
                    <button
                        onClick={closeModal}
                        type="button"
                        className="flex items-center justify-center w-full middle:w-[50%] h-[38px] gap-[12px] rounded-[6px] 
                                   bg-[#FFFFFF] border-[1px] border-[#D8D8DA] text-[#202020] text-[14px] font-semibold"
                    >
                        Cancel
                    </button>

                    <PrimaryButton
                        onClick={handleDbRequest}
                        type="submit"
                        form="edit-form"
                        className="flex items-center justify-center shrink-0 w-full middle:w-[50%] h-[38px] rounded-[6px] bg-[#2C71F6] 
                                 disabled:text-white disabled:bg-[#BBD1FC]"
                    >
                        {loading ? (
                            <Spinner className="border-[#2C71F6]" />
                        ) : (
                            'Confirm'
                        )}
                    </PrimaryButton>
                </div>
            </div>
        </Dialog>
    );
};
