import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { UserContext } from '../../context/UserContext';
import {
    handleOnCallCancelSubscriptionAtPeriodEnd,
    handleOnCallCreateStripeSession,
} from '../../util/Stripe';
import SubscriptionCards, {
    SubscriptionCardsDataType,
} from './SubscriptionCards';
import { Button } from '../../ui-lib/components/Button/Button';
import { DowngradeModal } from '../../components/Modals/DowngradeModal';
import { useDowngrade } from './hooks/useDowngrade';
import { ChangeCurrentPlanModal } from '../../components/Modals/ChangeCurrentPlanModal';
import { useUserLimitationState } from '../../context/UserLimitationContext';
import { SuccessCanceledPlanModal } from '../../components/Modals/SuccessCanceledPlanModal';

export type InitialStateType =
    | {
          isYearly: boolean;
          initialOption: { value: string; name: string } | null;
      }
    | undefined;

export const subscriptionCardsData: SubscriptionCardsDataType[] = [
    {
        title: 'Basic',
        subTitle:
            'No monthly fee. Seriously. Try out free version with basic features and styles.',
        priceMonthly: 0,
        priceYearly: 0,
        priceTag: 'Free',
        priceIdMonthly: '',
        priceIdYearly: '',
        subPrice: 'For individuals (1 user)',
        options: [
            'Unique digital card',
            'Unlimited card sharing',
            'Unlimited links and fields',
            'Unique QR code',
            'Contacts management',
            'Leads form',
            'Basic theme',
            'Swapkaart logo',
        ],
        footnote: 'Free forever. No credit card needed.',
        optional: 'Order custom NFC Business card with your own design',
        link: '//www.swapkaart.com/shop',
        button: 'Shop now',
    },
    {
        title: 'Pro',
        subTitle: 'Unlock the power of customization and additional features.',
        priceMonthly: 4.99,
        priceYearly: 49.9,
        priceTag: '',
        priceIdMonthly: 'price_1O2uWeEPvpNoagEsbgB8bQIJ',
        priceIdYearly: 'price_1O2uYAEPvpNoagEsR5G3hb7n',
        subPrice: 'For individuals (1 user)',
        options: [
            '2 digital cards',
            'Unlimited card sharing',
            'Unlimited links and fields',
            'Unique QR code',
            'Contacts management',
            'Leads form',
            'Pro Themes',
            'Hide Swapcaart logo',
            'Custom colours',
            'Embedded widgets',
            'Apple Wallet',
            'Google Wallet',
            'Export contacts as CSV',
            'Attachments',
            '5000+ Zapier integrations',
        ],
        trailPeriod: 7,
        footnote: '7 day free trial',
        optional: 'Order custom NFC Business card with your own design',
        link: '//www.swapkaart.com/shop',
        button: 'Shop now',
    },
    {
        title: 'Teams',
        subTitle: 'Everything you need to manage business cards in one place',
        priceMonthly: [9.99, 19.99, 39.99, 79.99, 179.99],
        priceYearly: [99.9, 199.9, 399.9, 799.9, 1799.9],
        priceTag: '',
        priceIdMonthly: [
            'price_1Pk58UEPvpNoagEsgkddBAVk',
            'price_1Pk59JEPvpNoagEstxzvhf9a',
            'price_1Pk59qEPvpNoagEs7jOsvB9O',
            'price_1Pk5AUEPvpNoagEs232abNJD',
            'price_1Pk5BJEPvpNoagEs9wp3VgUz',
        ],
        priceIdYearly: [
            'price_1PoPIJEPvpNoagEsndUuhHRn',
            'price_1PoPJDEPvpNoagEsi9v9upaw',
            'price_1PoPJuEPvpNoagEs4Nkcxwhq',
            'price_1PoPLuEPvpNoagEsmOYCoRfg',
            'price_1PoPNBEPvpNoagEsUmxtLiFG',
        ],
        subPrice: 'For companies',
        options: [
            'Unlimited digital cards',
            'Unlimited card sharing',
            'Unlimited links and fields',
            'Unique QR code',
            'Contacts management',
            'Leads form',
            'Pro Themes',
            'Hide Swapcaart logo',
            'Custom colours',
            'Embedded widgets',
            'Apple Wallet',
            'Google Wallet',
            'Export contacts as CSV',
            'Attachments',
            '5000+ Zapier integrations',
            'Admin dashboard',
            'User management',
            'User permissions as roles',
            'Admin leads overview',
            'Excel bulk import',
            'API access',
            'Onboarding assistance',
        ],
        footnote: '14 day free trial',
        trailPeriod: 14,
        optional: 'Add custom NFC Business card with your own design ',
        link: '//www.swapkaart.com/for-businesses#wf-form-For-companies',
        button: 'Get a quote',
    },
    // {
    //     title: 'Enterprise',
    //     subTitle: 'Enterprise-ready integrations, automations and compliance',
    //     priceMonthly: 0,
    //     priceYearly: 0,
    //     priceTag: 'Contact us',
    //     priceIdMonthly: '',
    //     priceIdYearly: '',
    //     subPrice: 'For organizations (100+ users)',
    //     options: [
    //         'Everything from Team plan plus',
    //         'White label solutions',
    //         'Bulk card creation via API',
    //         'Native CRM integrations',
    //         'Export contacts using Webhooks',
    //     ],
    //     footnote: 'Get a tailor made offer',
    //     minUsers: 100,
    //     maxUsers: 100,
    // },
];

const Subscription = () => {
    const [selectedPayment, setSelectedPayment] = useState(
        {} as SubscriptionCardsDataType
    );
    const { isDowngraded, fromSubscription, toSubscription } = useDowngrade(
        selectedPayment.title
    );
    const [isYearly, setIsYearly] = useState(false);
    const [cardsPriceRangeIndex, setCardsPriceRangeIndex] = useState(0);
    const { user, subscription } = useContext(UserContext);
    const [isLoading, setIsLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [isChangeCurrentPlanModalOpen, setIsChangeCurrentPlanModalOpen] =
        useState<boolean>(false);
    const [isSuccessCanceledPlanModalOpen, setIsSuccessCanceledPlanModalOpen] =
        useState<boolean>(false);
    const [isChangeCurrentPlan, setIsChangeCurrentPlan] =
        useState<boolean>(false);
    const navigate = useNavigate();
    const [initialPaidPlan, setInitialPaidPlan] = useState<InitialStateType>();
    const { isAccountBlocked } = useUserLimitationState();
    const [isSubscriptionCardBlocked, setIsSubscriptionCardBlocked] =
        useState<boolean>(true);

    useEffect(() => {
        if (
            selectedPayment.title === 'Pro' &&
            subscription === 'subscribed' &&
            !isYearly
        ) {
            setIsChangeCurrentPlan(true);
        } else if (
            selectedPayment.title === 'Pro' &&
            subscription === 'subscribed' &&
            !isAccountBlocked
        ) {
            setIsChangeCurrentPlan(true);
        } else {
            setIsChangeCurrentPlan(false);
        }

        if (
            selectedPayment.title === 'Teams' &&
            subscription === 'team' &&
            initialPaidPlan?.initialOption?.value &&
            (+initialPaidPlan?.initialOption?.value < cardsPriceRangeIndex ||
                (+initialPaidPlan?.initialOption?.value ===
                    cardsPriceRangeIndex &&
                    isYearly))
        ) {
            setIsChangeCurrentPlan(false);
        } else if (
            selectedPayment.title === 'Teams' &&
            subscription === 'team' &&
            isAccountBlocked &&
            !isSubscriptionCardBlocked
        ) {
            setIsChangeCurrentPlan(true);
        } else if (
            selectedPayment.title === 'Teams' &&
            subscription === 'team'
        ) {
            setIsChangeCurrentPlan(true);
        }
    }, [
        isYearly,
        selectedPayment,
        cardsPriceRangeIndex,
        isAccountBlocked,
        isSubscriptionCardBlocked,
    ]);

    const handleDbRequest = async (
        selectedPayment: SubscriptionCardsDataType,
        isYearly: boolean,
        quantity: number = 1
    ) => {
        const paymentTitle = selectedPayment.title.toLocaleLowerCase();

        if (paymentTitle === 'basic') {
            if (subscription !== 'basic' && user) {
                try {
                    setIsLoading(true);
                    await handleOnCallCancelSubscriptionAtPeriodEnd(user.uid);
                    setIsLoading(false);
                    setIsModalOpen(false);
                    setIsSuccessCanceledPlanModalOpen(true);
                } catch (error) {
                    setIsLoading(false);
                    console.error('Error canceling subscription:', error);
                }
            } else {
                navigate('/');
            }
            return;
        }

        if (paymentTitle === 'enterprise') {
            window.location.href =
                'https://www.swapkaart.com/for-businesses#wf-form-For-companies';
            return;
        }

        setIsLoading(true);

        let priceId: string | number;
        if (isYearly) {
            priceId = Array.isArray(selectedPayment.priceIdYearly)
                ? selectedPayment.priceIdYearly[cardsPriceRangeIndex]
                : selectedPayment.priceIdYearly;
        } else {
            priceId = Array.isArray(selectedPayment.priceIdMonthly)
                ? selectedPayment.priceIdMonthly[cardsPriceRangeIndex]
                : selectedPayment.priceIdMonthly;
        }

        const checkoutUrl = await handleOnCallCreateStripeSession(
            user!.uid,
            priceId,
            quantity,
            window.location.origin,
            selectedPayment.trailPeriod
        );

        sessionStorage.setItem('subscription', selectedPayment.title);

        window.location.href = checkoutUrl;
        setIsLoading(false);
    };

    const callHandleDbRequest = () => {
        return handleDbRequest(selectedPayment, isYearly);
    };

    return (
        <>
            <h1 className="text-h1 whitespace-nowrap pt-4 text-center">
                Choose your pricing plan
            </h1>
            <div className="flex justify-between overflow-x-hidden tableWidth:overflow-visible p-2">
                <div className="flex flex-col p-0 w-full gap-y-8 items-center mb-5 md:mb-0">
                    <SubscriptionCards
                        initialPaidPlan={initialPaidPlan}
                        setInitialPaidPlan={setInitialPaidPlan}
                        subscriptionCardsData={subscriptionCardsData}
                        selectedPayment={selectedPayment}
                        setSelectedPayment={setSelectedPayment}
                        isYearly={isYearly}
                        setIsYearly={setIsYearly}
                        cardsPriceRangeIndex={cardsPriceRangeIndex}
                        setCardsPriceRangeIndex={setCardsPriceRangeIndex}
                        isSubscriptionCardBlocked={isSubscriptionCardBlocked}
                        setIsSubscriptionCardBlocked={
                            setIsSubscriptionCardBlocked
                        }
                        isAccountBlocked={isAccountBlocked}
                    />
                    <div className="flex flex-col md:flex-row gap-3 md:items-center md:justify-end border border-t-gray middle:border-none justify-center p-3 fixed bottom-0 middle:static z-20 bg-white w-full">
                        <Button
                            className="w-full md:w-auto text-blue-500 hidden md:flex"
                            type={'button'}
                            onClick={() => navigate(-1)}
                            impact="link"
                        >
                            Skip
                        </Button>
                        <Button
                            className="w-full md:w-[5.25rem]"
                            type={'button'}
                            onClick={() => {
                                if (isChangeCurrentPlan) {
                                    setIsChangeCurrentPlanModalOpen(true);
                                } else if (isDowngraded) {
                                    setIsModalOpen(true);
                                } else {
                                    callHandleDbRequest();
                                }
                            }}
                            disabled={
                                !selectedPayment.title ||
                                isLoading ||
                                cardsPriceRangeIndex === 5
                            }
                            loading={!isDowngraded ? isLoading : false}
                        >
                            Next
                        </Button>
                        <Button
                            className="w-full md:w-auto text-blue-500 md:hidden"
                            type={'button'}
                            onClick={() => navigate(-1)}
                            impact="link"
                        >
                            Skip
                        </Button>
                    </div>
                </div>
            </div>
            {isModalOpen && (
                <DowngradeModal
                    isOpen={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                    closeModal={() => setIsModalOpen(false)}
                    handleDbRequest={callHandleDbRequest}
                    loading={isLoading}
                    fromSubscription={fromSubscription}
                    toSubscription={toSubscription}
                />
            )}
            {isChangeCurrentPlanModalOpen && (
                <ChangeCurrentPlanModal
                    isOpen={isChangeCurrentPlanModalOpen}
                    onClose={() => setIsChangeCurrentPlanModalOpen(false)}
                    closeModal={() => setIsChangeCurrentPlanModalOpen(false)}
                    handleDbRequest={callHandleDbRequest}
                    loading={isLoading}
                />
            )}
            {isSuccessCanceledPlanModalOpen && (
                <SuccessCanceledPlanModal
                    isOpen={isSuccessCanceledPlanModalOpen}
                    onClose={() => setIsSuccessCanceledPlanModalOpen(false)}
                    closeModal={() => setIsSuccessCanceledPlanModalOpen(false)}
                />
            )}
        </>
    );
};

export default Subscription;
