import {
    getAdditionalUserInfo,
    getAuth,
    GoogleAuthProvider,
    onAuthStateChanged,
    sendPasswordResetEmail,
    signInWithEmailAndPassword,
    signInWithPopup,
    signOut,
    updateEmail,
    updateProfile,
} from 'firebase/auth';
import { auth } from './firebaseProviders';
import { logError, sendEmailWithTemplate } from './Firestore';
import { handleChangeUserPassword, handleUserCustomClaims } from './Functions';

const googleProvider = new GoogleAuthProvider();

// Reset password
export async function resetPassword(email: string) {
    try {
        return await sendPasswordResetEmail(auth, email);
    } catch (error: any) {
        logError(String(error.message));
        throw new Error(error);
    }
}

export async function checkUserIsLoggedIn() {
    try {
        let userData: any = '';
        onAuthStateChanged(auth, (user) => {
            if (user) {
                userData = user;
                return userData;
                // ...
            } else {
                userData = false;
                // return false;
            }
        });
    } catch (error: any) {
        logError(String(error.message));
        console.log(error);
        throw new Error(error);
    }
}

export const googleSignIn = async () => {
    try {
        const data = await signInWithPopup(auth, googleProvider);
        if (data) {
            const res = GoogleAuthProvider.credentialFromResult(data);
            const newUser = getAdditionalUserInfo(data);
            if (newUser?.isNewUser) {
                await handleUserCustomClaims({ uid: data.user.uid });
                await sendEmailWithTemplate(
                    'newCustomerWithoutACard',
                    {
                        cta1: 'https://app.swapkaart.com/login',
                        cta2: 'https://app.swapkaart.com/login',
                    },
                    data.user.email!
                );
                window.location.href = '/onboarding';
            }
            return data.user;
        }
    } catch (e: any) {
        logError(String(e.message));
        throw new Error(e);
    }
};

// Sign in with Email and password
export async function emailSignIn(email: string, password: string) {
    //   let result;
    try {
        const user = await signInWithEmailAndPassword(auth, email, password);
        return user;
    } catch (error: any) {
        logError(String(error.message));
        throw new Error(error);
    }
}

//check what provider was used for logging in

export const checkLogiInProvider = () => {
    return getAuth().currentUser?.providerData[0].providerId;
};

//update user profile
export async function updateUserData(data: any) {
    //getting current user
    const user = getAuth().currentUser;
    if (user) {
        try {
            // If password is filled in
            if (data.password) {
                if (checkLogiInProvider() === 'google.com') {
                    await Promise.allSettled([
                        await googleSignIn(),
                        await updateProfile(user, {
                            displayName: data.displayName,
                            photoURL: data.photoURL,
                        }),

                        await handleChangeUserPassword({
                            uid: user.uid,
                            newPassword: data.password,
                        }),
                    ]);
                } else {
                    // try log in to check if password is correct
                    await Promise.allSettled([
                        await signInWithEmailAndPassword(
                            auth,
                            data.email,
                            data.oldPassword
                        ),
                        await updateProfile(user, {
                            displayName: data.displayName,
                            photoURL: data.photoURL,
                        }),

                        await handleChangeUserPassword({
                            uid: user.uid,
                            newPassword: data.password,
                        }),
                    ]);
                }
                window.location.reload();
            } else {
                await Promise.allSettled([
                    await updateProfile(user, {
                        displayName: data.displayName,
                        photoURL: data.photoURL,
                    }),
                    await updateEmail(user, data.email),
                ]);
            }
            return getAuth().currentUser;
        } catch (error: any) {
            logError(String(error.message));
            console.log(error.message);
            throw new Error(error);
        }
    }
}

// Sign out
export async function signOutUser() {
    await signOut(auth)
        .then(() => {
            localStorage.removeItem('isUserLoggedIn');
            sessionStorage.removeItem('isUserLoggedIn');
        })
        .catch((error) => {
            throw new Error(error);
        });
}

//check admin role
export async function getUserRole() {
    try {
        const currUserRole = await auth.currentUser?.getIdTokenResult(true);
        if (currUserRole?.claims) return currUserRole?.claims.userRole;
    } catch (e: any) {
        logError(String(e.message));
        throw new Error(e.message);
    }
}

//get user claims
export async function getUserClaims() {
    try {
        const currUserRole = await auth.currentUser?.getIdTokenResult(true);
        if (currUserRole?.claims) return currUserRole?.claims;
    } catch (e: any) {
        logError(String(e.message));
        throw new Error(e.message);
    }
}

export async function getUserIdToken() {
    try {
        return await auth.currentUser?.getIdToken(false);
    } catch (e: any) {
        logError(String(e.message));
        throw new Error(e.message);
    }
}
export async function getRefreshToken() {
    try {
        return await auth.currentUser?.refreshToken;
    } catch (e: any) {
        logError(String(e.message));
        throw new Error(e.message);
    }
}
