import { Spinner } from '../../../../ui-lib/components/Spinner/Spinner';
import { Integration } from '../index';
import { ChevronRightIcon } from '@heroicons/react/24/outline';

export const IntegrationItem = ({
    integration,
    linkTitle,
    loading,
}: {
    integration: Integration;
    linkTitle: string | undefined;
    loading: boolean;
}) => {
    return (
        <div className="border rounded-lg w-full md:max-w-[334px] ">
            <div className="flex flex-col gap-y-3  p-4 border-b">
                <div className="flex items-center gap-x-3">
                    <div>
                        <img src={integration.icon} alt={integration.title} />
                    </div>
                    <h3 className="text-[16px] text-[#202020] font-medium">
                        {integration.title}
                    </h3>
                </div>

                <h4 className="text-[14px] text-[#89898A]">
                    {integration.subTitle}
                </h4>
            </div>
            <div className="flex ms-4 h-12 text-[#2C71F6] font-medium">
                {!loading ? (
                    <a
                        href={integration.link}
                        target="_blank"
                        className="flex items-center"
                    >
                        <div>{linkTitle}</div>
                        <div>
                            <ChevronRightIcon className="w-[20px] h-[20px] text-[#2C71F6]" />
                        </div>
                    </a>
                ) : (
                    <Spinner className="flex h-full" />
                )}
            </div>
        </div>
    );
};
